import {
  MDBBox,
  MDBMask,
  MDBModal,
  MDBModalBody,
  MDBTypography,
  MDBView,
} from "mdbreact";
import Image from "app/layout/image";
import React, { useEffect, useState } from "react";
import GalleryModal from "./GalleryModal";
import Icon from "app/assets/icon/icon";

const GalleryWidget = ({
  thumbnail = "https://via.placeholder.com/370x420",
  name = "name",
  id,
  galleries,
  image,
  pdp = false,
  setSelectedGallery = () => {},
}) => {
  let bottomGalleryImages = galleries.filter((item) => {
    return item.parent_id === id && item;
  });

  const path = name
    ? `/inspirations/${name
        .split(" ")
        .join("-")
        .toLowerCase()
        .replace(/,/, "")
        .replace(/-$/, "")}`
    : "/inspirations/";

  const [popupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    if (pdp) return;
    if (popupVisible) {
      document.body.classList.add("hidden-overflow");
    } else if (document.body.classList.contains("hidden-overflow")) {
      document.body.classList.remove("hidden-overflow");
    }
    setTimeout(() => {
      document.body.classList.remove("overflow-y-scroll");
    }, 0);
  }, [popupVisible]);
  return (
    <MDBBox className="gallery-item">
      <MDBView
        hover
        className={`d-flex flex-column justify-content-center cursor-pointer gallery-border`}
        onClick={() => {
          if (pdp)
            setSelectedGallery({
              image,
              bottomGalleryImages,
              path,
            });
          setPopupVisible(true);
        }}
      >
        <Image
          className="img-fluid our-installation-img"
          source={thumbnail}
          alt={name}
          loaderAsSkeleton={true}
        />
        {bottomGalleryImages.length > 0 && (
          <MDBBox
            className={`d-flex position-absolute num-of-images-wrapper`}
            onClick={() => {
              if (pdp)
                setSelectedGallery({
                  image,
                  bottomGalleryImages,
                  path,
                });
              setPopupVisible(true);
            }}
          >
            <MDBBox className={`position-relative mt-4 mr-4`}>
              <MDBBox
                className={`fs-14 fwmedium text-white position-absolute num-of-images ${
                  bottomGalleryImages.length + 1 > 9 && "more-then-nine-images"
                } `}
              >
                {bottomGalleryImages.length + 1}
              </MDBBox>
              <Icon icon="multipleImageIcon" />
            </MDBBox>
          </MDBBox>
        )}
        {/* <MDBMask overlay="black-light">
          <MDBBox
            className="d-flex flex-column h-100 justify-content-between"
            onClick={() => {
              if (pdp)
                setSelectedGallery({
                  image,
                  bottomGalleryImages,
                  path,
                });
              setPopupVisible(true);
            }}
          >
            <MDBBox className={"position-absolute w-100 fixed-bottom"}>
              <MDBBox
                className="flex-center py-3 rgba-white-strong"
                overlay={"white-strong"}
              >
                <MDBTypography
                  tag="h2"
                  className="body-2 text-center"
                  dangerouslySetInnerHTML={{ __html: name }}
                />
              </MDBBox>
            </MDBBox>
          </MDBBox>
        </MDBMask> */}
        <MDBBox
          onClick={() => {
            if (pdp)
              setSelectedGallery({
                image,
                bottomGalleryImages,
                path,
              });
            setPopupVisible(true);
          }}
          className="flex-center items-center mt-20 mb-10 rgba-white-strong"
          overlay={"white-strong"}
        >
          <MDBTypography
            tag="h2"
            className="gallery-title text-center"
            dangerouslySetInnerHTML={{ __html: name }}
          />
        </MDBBox>
      </MDBView>
      {!pdp ? (
        <GalleryModal
          popupVisible={popupVisible}
          setPopupVisible={setPopupVisible}
          path={path}
          bottomGalleryImages={bottomGalleryImages}
          image={image}
        />
      ) : null}
    </MDBBox>
  );
};

export default GalleryWidget;
