// TODO add back // @ts-heck

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const ALL_GALLERIES = gql`
  query {
    getAllGalleryData {
      category
      description
      id
      hotspot {
        content
        image
        name
      }
      parent_id
      image
      thumbnail
      name
      skuList
    }
  }
`;

export const useGallery = () => {
  const { data, loading, error } = useQuery(ALL_GALLERIES, {
    variables: { id: "gallery-details" },
  });
  if (loading) {
    return {};
  }
  if (error) {
    console.error(error);
    return {};
  }

  let galleries = data.getAllGalleryData;

  // Create a list of unique categories with order
  let categories = [];
  const categoryNames = new Set();

  const categoryOrder = ["All", "Lookbooks", "Sourcebooks", "Informational"];

  if (galleries) {
    galleries.forEach((g) => {
      g.category.forEach((c) => {
        if (c && !categoryNames.has(c)) {
          categoryNames.add(c);
        }
      });
    });
  }

  // Add predefined categories in order
  categoryOrder.forEach((category, index) => {
    if (categoryNames.has(category)) {
      categories.push({
        name: category,
        id: index,
      });
      categoryNames.delete(category);
    }
  });

  // Add any remaining categories that were not in the predefined list
  categoryNames.forEach((category) => {
    categories.push({
      name: category,
      id: categories.length, // Assign the next available index
    });
  });

  return { galleries, categories };
};
