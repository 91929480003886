import React from "react";
import MagicSliderDots from "react-magic-slider-dots";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { MDBBox, MDBCol, MDBRow, MDBTypography, MDBContainer } from "mdbreact";
import Icon from "../../../../../assets/icon/icon";

export const getBadges = (product) =>
  product
    ? Object.entries({
        senior: product?.ffi_lanesl,
        retail: product?.ffi_laners,
        contract: product?.ffi_lanect,
      })
        .filter(([, value]) => !!value)
        .map(([key], i, a) => (
          <div key={key} className={i !== a.length - 1 ? "mr-5" : ""}>
            <Icon icon={key} />
          </div>
        ))
    : null;

const RelatedProducts = ({ relatedProducts }) => {
  const sliderProps = {
    className: "related-slider",
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    controls: false,
    arrows: true,
    dots: true,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={30} />;
    },
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          controls: false,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 3000,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      {relatedProducts && relatedProducts.length > 0 && (
        <MDBContainer className="related-container-wrap">
          <div className={"related-container"}>
            <MDBRow className="w-100 m-0">
              <MDBCol className="d-none d-lg-block px-0">
                <MDBBox className="h-50 border-bottom border-default w-100" />
              </MDBCol>
              <MDBCol className="d-flex justify-content-center p-0 align-items-center">
                <MDBTypography
                  className="related-title font-weight-light"
                  tag="h2"
                  variant="display-2"
                >
                  Related Products
                </MDBTypography>
              </MDBCol>
              <MDBCol className="d-none d-lg-block px-0">
                <MDBBox className="h-50 border-bottom border-default w-100" />
              </MDBCol>
            </MDBRow>

            <Slider {...sliderProps} className="related-products-slider">
              {relatedProducts.map((product, index) => {
                return (
                  <Link to={`/${product.url_key}`} key={index}>
                    <div className={"product-container"}>
                      <img src={product.image.url} alt={product.image.label} />
                      <p>
                        <MDBRow className="">
                          <MDBTypography
                            tag={"h4"}
                            className={`w-100 text-center`}
                          >
                            {product?.name}
                          </MDBTypography>
                        </MDBRow>

                        <MDBRow className="">
                          <MDBTypography
                            tag={"h5"}
                            className={`w-100 text-center font-weight-bold`}
                          >
                            {product?.ffi_extdesc2 || ''}
                          </MDBTypography>
                        </MDBRow>

                        <MDBRow className="">
                          <MDBTypography
                            tag={"h5"}
                            className={`w-100 text-center`}
                          >
                            {product.sku}
                          </MDBTypography>
                        </MDBRow>
                      </p>
                      {/* <div className={"badges"}>
                        <div className={"badge-container"}>
                          {getBadges(product)}
                        </div>
                      </div> */}
                    </div>
                  </Link>
                );
              })}
            </Slider>
          </div>
        </MDBContainer>
      )}
    </>
  );
};

export default RelatedProducts;
