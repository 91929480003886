import React from "react";
import { useQuery } from "react-apollo";
import { GET_RECENTLY_VIEWED } from "./recentlyViewed.gql";
import { MDBCol, MDBRow, MDBTypography, MDBBox } from "mdbreact";
import MagicSliderDots from "react-magic-slider-dots";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const RecentlyViewed = () => {
  const { data } = useQuery(GET_RECENTLY_VIEWED, {
    fetchPolicy: "cache-and-network",
  });
  const sliderProps = {
    className: "related-slider",
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    controls: false,
    arrows: true,
    dots: true,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={30} />;
    },
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          controls: false,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 3000,
          dots: false,
        },
      },
    ],
  };
  const recentlyViewedProducts = data?.getRecentlyViewedProducts || [];
  if(!recentlyViewedProducts?.length) {
    return null;
  }
  return (
    <div className="container related-container-wrap">
      <div className={"related-container"}>
        <MDBRow className="w-100 m-0">
          <MDBCol className="d-none d-lg-block px-0">
            <MDBBox className="h-50 border-bottom border-default w-100" />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center p-0 align-items-center">
            <MDBTypography
              className="related-title font-weight-light"
              tag="h2"
              variant="display-2"
            >
              Recently Viewed
            </MDBTypography>
          </MDBCol>
          <MDBCol className="d-none d-lg-block px-0">
            <MDBBox className="h-50 border-bottom border-default w-100" />
          </MDBCol>
        </MDBRow>
        <Slider {...sliderProps} className="related-products-slider">
          {recentlyViewedProducts.map((product, index) => {
            return (
              <Link to={`/${product.url_key}`} key={index}>
                <div className={"product-container"}>
                  <img src={product.image.url} alt={product.image.label} />
                  <p>
                    <MDBRow className="">
                      <MDBTypography tag={"h4"} className={`w-100 text-center`}>
                        {product?.name}
                      </MDBTypography>
                    </MDBRow>

                    <MDBRow className="">
                      <MDBTypography
                        tag={"h5"}
                        className={`w-100 text-center font-weight-bold`}
                      >
                        {product?.ffi_extdesc2 || ""}
                      </MDBTypography>
                    </MDBRow>

                    <MDBRow className="">
                      <MDBTypography tag={"h5"} className={`w-100 text-center`}>
                        {product.sku}
                      </MDBTypography>
                    </MDBRow>
                  </p>
                  {/* <div className={"badges"}>
                        <div className={"badge-container"}>
                          {getBadges(product)}
                        </div>
                      </div> */}
                </div>
              </Link>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default RecentlyViewed;
