import Icon from "app/assets/icon/icon";
import Breadcrumbs from "app/layout/breadcrumbs";
import Checkbox from "app/layout/Checkbox";
import { Collapse } from "app/layout/Collapse";
import FileInput from "app/layout/FileInput";
import Label from "app/layout/Label";
import Pagination from "app/layout/Pagination/Pagination";
import ProductWidget from "app/layout/productWidget";
import RadioButton from "app/layout/RadioButton";
import Steps from "app/layout/registration/Steps";
import Select from "app/layout/Select";
import Switch from "app/layout/Switch";
import Price from "core/components/price";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBFormInline,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import * as yup from "yup";
import CmsBlock from "app/layout/cmsBlock";

const options = [
  { label: "Designer", value: "a" },
  { label: "Retail", value: "b" },
];

const Typography = () => {
  /**
   * @type {[string, React.Dispatch<React.SetStateAction<string>>]}
   */
  const [fileName, setFileName] = React.useState("");
  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  const handleFileChange = (e) => {
    const firstFile = e.target.files?.[0];
    if (firstFile) {
      setFileName(firstFile.name);
    }
  };
  const [switched, setSwitched] = React.useState(true);
  const [checked, setChecked] = React.useState(false);
  /**
   * @typedef {string} Gender
   */
  /**
   * @type {[Gender, React.Dispatch<React.SetStateAction<Gender>>]}
   */
  const [radio, setRadio] = React.useState("female");

  const [option, setOption] = React.useState(options[0]);

  const cart = {
    items: [
      {
        name: "Item1",
        customizable_options: [
          {
            label: "Option Title 1",
            value: [
              {
                label: "Value Name 1",
                price: { value: 199 },
              },
            ],
          },
          {
            label: "Option Title 2",
            value: [
              {
                label: "Value Name 2",
                price: { value: 299 },
              },
            ],
          },
          {
            label: "Option Title 3",
            value: [
              {
                label: "Value Name 3",
                price: { value: 399 },
              },
            ],
          },
          {
            label: "Option Title 4",
            value: [
              {
                label: "Value Name 4",
                price: { value: 499 },
              },
            ],
          },
        ],
      },
    ],
  };

  return (
    <>
    <CmsBlock id="timeline1" />
    <CmsBlock id="timeline2" />
      <MDBContainer className="py-5">
        <MDBTypography
          tag="h1"
          className="position-lg-sticky"
          style={{ top: "10px", border: "1px solid red" }}
        >
          pera
        </MDBTypography>
        <MDBBox>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus,
          ullam, quisquam omnis temporibus maiores magnam accusantium animi
          laborum iste doloremque saepe rem nulla dolores corporis excepturi qui
          amet ipsam illum?
        </MDBBox>
        <MDBBox>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus,
          ullam, quisquam omnis temporibus maiores magnam accusantium animi
          laborum iste doloremque saepe rem nulla dolores corporis excepturi qui
          amet ipsam illum?
        </MDBBox>
        <MDBBox>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus,
          ullam, quisquam omnis temporibus maiores magnam accusantium animi
          laborum iste doloremque saepe rem nulla dolores corporis excepturi qui
          amet ipsam illum?
        </MDBBox>
        <MDBBox>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus,
          ullam, quisquam omnis temporibus maiores magnam accusantium animi
          laborum iste doloremque saepe rem nulla dolores corporis excepturi qui
          amet ipsam illum?
        </MDBBox>
        <MDBBox>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus,
          ullam, quisquam omnis temporibus maiores magnam accusantium animi
          laborum iste doloremque saepe rem nulla dolores corporis excepturi qui
          amet ipsam illum?
        </MDBBox>
      </MDBContainer>
      <MDBContainer className="mb-5 pb-5">
        <MDBRow>
          <MDBTypography tag="h2">Products</MDBTypography>
        </MDBRow>
        <MDBRow>
          <MDBCol size="2">
            <img
              src="https://fairfieldm2.joladev.com/media/catalog/product/cache/9f8421177d6e4d2de01feb59d2f9bd0b/5/1/5198-A2_1.jpg"
              alt="foo"
              className="img-fluid"
            />
          </MDBCol>
          <MDBCol size="2">
            <img
              src="https://fairfieldm2.joladev.com/media/catalog/product/cache/9f8421177d6e4d2de01feb59d2f9bd0b/5/1/5198-A2_1.jpg"
              alt="foo"
              className="img-fluid"
            />
          </MDBCol>
          <MDBCol size="2">
            <img
              src="https://fairfieldm2.joladev.com/media/catalog/product/cache/9f8421177d6e4d2de01feb59d2f9bd0b/5/1/5198-A2_1.jpg"
              alt="foo"
              className="img-fluid"
            />
          </MDBCol>
          <MDBCol size="2">
            <img
              src="https://fairfieldm2.joladev.com/media/catalog/product/cache/9f8421177d6e4d2de01feb59d2f9bd0b/5/1/5198-A2_1.jpg"
              alt="foo"
              className="img-fluid"
            />
          </MDBCol>
        </MDBRow>
        <Collapse title="example">
          <MDBRow>
            <MDBCol size="2">
              <img
                src="https://fairfieldm2.joladev.com/media/catalog/product/cache/9f8421177d6e4d2de01feb59d2f9bd0b/6/4/6406-01_pewter_back_1.jpg"
                alt="foo"
                className="img-fluid"
              />
            </MDBCol>
            <MDBCol size="2">
              <img
                src="https://fairfieldm2.joladev.com/media/catalog/product/cache/9f8421177d6e4d2de01feb59d2f9bd0b/6/4/6406-01_pewter_back_1.jpg"
                alt="foo"
                className="img-fluid"
              />
            </MDBCol>
            <MDBCol size="2">
              <img
                src="https://fairfieldm2.joladev.com/media/catalog/product/cache/9f8421177d6e4d2de01feb59d2f9bd0b/6/4/6406-01_pewter_back_1.jpg"
                alt="foo"
                className="img-fluid"
              />
            </MDBCol>
            <MDBCol size="2">
              <img
                src="https://fairfieldmagento.jolade2.com/media/catalog/product/cache/9f8421177d6e4d2de01feb59d2f9bd0b/6/4/6406-01_pewter_back_1.jpg"
                alt="foo"
                className="img-fluid"
              />
            </MDBCol>
          </MDBRow>
        </Collapse>
        <MDBRow>
          <MDBTypography tag="h2">Lifestyle</MDBTypography>
        </MDBRow>
      </MDBContainer>

      <MDBContainer>
        <Collapse title="Selections">
          <MDBRow>
            {cart.items[0].customizable_options.map((custOpt) => {
              return (
                <MDBCol key={custOpt.label} size="12" lg="6">
                  <MDBRow>
                    <MDBCol size="4">{custOpt.label}</MDBCol>
                    <MDBCol size="4">{custOpt.value[0].label}</MDBCol>
                    <MDBCol size="4">
                      <Price
                        price={custOpt.value[0].price.value}
                        type={"fixed"}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              );
            })}
          </MDBRow>
        </Collapse>
      </MDBContainer>
      <MDBContainer
        className="mb-5 pb-5"
        style={{ backgroundColor: "#eceff1" }}
      >
        <MDBRow className="my-5 bg-white py-5">
          <MDBCol size="12">
            <Collapse
              // @ts-ignore
              title="Demo Collapse"
              content={"lorem ipsum dolor sit amet"}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <Select
              placeholder="Select option..."
              value={option}
              options={options}
              onChange={setOption}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <Breadcrumbs />
          </MDBCol>
          <MDBCol size="12">
            <MDBTypography tag="h2" variant="display-1">
              Display 1
            </MDBTypography>
          </MDBCol>
          <MDBCol size="12" className="">
            <MDBTypography tag="h2" variant="display-2">
              Display 2
            </MDBTypography>
          </MDBCol>
          <MDBCol size="12" className="">
            <MDBTypography tag="h2" variant="display-3">
              Display 3
            </MDBTypography>
          </MDBCol>
          <MDBCol size="12" className="">
            <MDBTypography tag="h2" variant="display-4">
              Display 4
            </MDBTypography>
          </MDBCol>
          <MDBCol size="12" className="">
            {/* @ts-ignore */}
            <MDBTypography tag="h2" variant="display-5">
              Display 5
            </MDBTypography>
          </MDBCol>
          <MDBCol size="12">
            <MDBTypography tag="h1">Header 1</MDBTypography>
            <MDBTypography tag="h2">Header 2</MDBTypography>
            <MDBTypography tag="h3">Header 3</MDBTypography>
            <MDBTypography tag="h4">Header 4</MDBTypography>
            <MDBTypography tag="h5" className="text-gray-darken-3">
              Header 5
            </MDBTypography>
          </MDBCol>
          <MDBCol size="12">
            {/* @ts-ignore */}
            <MDBTypography tag="span" className="body-1">
              Body 1
            </MDBTypography>
          </MDBCol>
          <MDBCol size="12">
            {/* @ts-ignore */}
            <MDBTypography tag="span" className="body-2">
              Body 2
            </MDBTypography>
          </MDBCol>
          <MDBCol size="12">
            {/* @ts-ignore */}
            <MDBTypography tag="span" className="body-3">
              Body 3
            </MDBTypography>
          </MDBCol>
          <MDBCol size="12">
            {/* @ts-ignore */}
            <MDBTypography tag="span" className="body-4">
              Body 4
            </MDBTypography>
          </MDBCol>
          <MDBCol size="12">
            {/* @ts-ignore */}
            <MDBTypography tag="span" className="body-5">
              Body 5
            </MDBTypography>
          </MDBCol>
          <MDBCol size="12">
            {/* @ts-ignore */}
            <MDBTypography tag="span" className="body-6">
              Body 6
            </MDBTypography>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="8">
            <MDBBtn size="sm">View All</MDBBtn>
            <MDBBtn>Default</MDBBtn>
            <MDBBtn size="lg">Default lg</MDBBtn>
          </MDBCol>
          <MDBCol>
            <MDBBtn className="w-100">Default w-100</MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="8">
            <MDBBtn color="secondary" size="sm">
              Sign Up
            </MDBBtn>
            <MDBBtn color="secondary">Secondary</MDBBtn>
            <MDBBtn color="secondary" size="lg">
              Secondary lg
            </MDBBtn>
          </MDBCol>
          <MDBCol>
            <MDBBtn color="secondary" className="w-100">
              Secondary w-100
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="8">
            <MDBBtn color="light-blue" size="sm">
              Sign Up
            </MDBBtn>
            <MDBBtn color="light-blue">light blue</MDBBtn>
            <MDBBtn color="light-blue" size="lg">
              Light blue lg
            </MDBBtn>
          </MDBCol>
          <MDBCol>
            <MDBBtn color="light-blue" className="w-100">
              Light blue w-100
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="8">
            <MDBBtn className="btn-flat" size="sm">
              Sign Up
            </MDBBtn>
            <MDBBtn className="btn-flat">light blue</MDBBtn>
            <MDBBtn className="btn-flat" size="lg">
              Light blue lg
            </MDBBtn>
          </MDBCol>
          <MDBCol>
            <MDBBtn className="btn-flat w-100">Light blue w-100</MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="8">
            <MDBBtn color="light-green" size="sm">
              View All
            </MDBBtn>
            <MDBBtn color="light-green">light green</MDBBtn>
            <MDBBtn color="light-green" size="lg">
              Light green lg
            </MDBBtn>
          </MDBCol>
          <MDBCol>
            <MDBBtn color="light-green" className="w-100">
              Light green w-100
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="8">
            <MDBBtn disabled size="sm" className="icon icon-left">
              <Icon icon={`plus`} /> View All
            </MDBBtn>
            <MDBBtn disabled className="icon icon-left">
              <Icon icon={`plus`} /> Disabled
            </MDBBtn>
            <MDBBtn disabled size="lg" className="icon icon-left">
              <Icon icon={`plus`} /> Disabled lg
            </MDBBtn>
          </MDBCol>
          <MDBCol>
            <MDBBtn disabled className="icon icon-left w-100">
              <Icon icon={`plus`} /> Disabled w-100
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="8">
            <MDBBtn size="sm" className="icon icon-left">
              <Icon icon={`plus`} /> View All
            </MDBBtn>
            <MDBBtn className="icon icon-left">
              <Icon icon={`plus`} /> Default Icon
            </MDBBtn>
            <MDBBtn size="lg" className="icon icon-left">
              <Icon icon={`plus`} /> Default lg Icon
            </MDBBtn>
          </MDBCol>
          <MDBCol>
            <MDBBtn className="icon icon-left w-100">
              <Icon icon={`plus`} /> Default w-100
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="8">
            <MDBBtn size="sm" color="info" className="icon icon-right">
              <Icon icon={`rightArrowOutline`} /> View All
            </MDBBtn>
            <MDBBtn color="info" className="icon icon-right">
              Info <Icon icon={`rightArrowOutline`} />
            </MDBBtn>
            <MDBBtn color="info" size="lg" className="icon icon-right ">
              Info lg
              <Icon icon={`rightArrowOutline`} />
            </MDBBtn>
          </MDBCol>
          <MDBCol>
            <MDBBtn color="info" className="icon icon-right w-100">
              Info w-100 <Icon icon={`rightArrowOutline`} />
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="8">
            <MDBBtn
              color="secondary"
              size="sm"
              className="icon icon-left btn-expand"
            >
              <Icon icon={`save`} /> Save for later
            </MDBBtn>
            <MDBBtn
              color="secondary"
              size="sm"
              className="icon icon-left btn-expand"
            >
              <Icon icon={`save`} /> Save for later
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-5">
          <MDBCol>
            <MDBFormInline>
              <MDBInput
                label="Disabled input"
                hint="email@email"
                disabled
                containerClass="mr-2"
              />
              <MDBInput
                required
                label="Email"
                hint="email@email"
                containerClass="mr-2"
              />
              <MDBInput
                label="Email"
                validate
                required
                type="email"
                hint="email@email"
                error="example error"
                className="required"
                containerClass="mr-2"
                size="lg"
              />
              <MDBBtn type="submit">Submit</MDBBtn>
            </MDBFormInline>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-5">
          <MDBCol>
            <Formik
              initialValues={{ firstName: "", lastName: "", email: "" }}
              validationSchema={yup.object({
                firstName: yup
                  .string()
                  .max(15, "Must be 15 characters or less")
                  .required("Required"),
                lastName: yup
                  .string()
                  .max(20, "Must be 20 characters or less")
                  .required("Required"),
                email: yup
                  .string()
                  .email("Invalid email address")
                  .required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              <Form>
                <Field
                  as={MDBInput}
                  label="First Name"
                  hint="Jane"
                  name="firstName"
                  type="text"
                />
                <ErrorMessage name="firstName" />
                <Field
                  as={MDBInput}
                  label="Last Name"
                  hint="Doe"
                  style={{ marginTop: 50 }}
                  name="lastName"
                  type="text"
                />
                <ErrorMessage name="lastName" />
                <Field
                  as={MDBInput}
                  label="Email"
                  hint="jane@doe.com"
                  style={{ marginTop: 50 }}
                  name="email"
                  type="email"
                />
                <ErrorMessage name="email" />
                <MDBBtn type="submit">Submit</MDBBtn>
              </Form>
            </Formik>
          </MDBCol>
        </MDBRow>
        <MDBRow className="my-5">
          <MDBCol>
            <FileInput
              label="Choose image"
              hint="Image file"
              accept={["image/*"]}
              value={fileName}
              onChange={handleFileChange}
              required
              text="Choose"
              buttonSize="sm"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="my-5">
          <MDBCol>
            <Label required text="Launch Rocket">
              <Switch
                label="Switch"
                checked={switched}
                onChange={(e) => {
                  setSwitched(e.target.checked);
                }}
              />
            </Label>
          </MDBCol>
        </MDBRow>
        <MDBRow className="my-5">
          <MDBCol>
            <Checkbox
              label="Checkbox"
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="my-5">
          <MDBCol>
            <RadioButton
              label="Female"
              name="gender"
              value="female"
              checked={radio === "female"}
              onChange={(e) => {
                setRadio(e.target.value);
              }}
            />
          </MDBCol>
          <MDBCol>
            <RadioButton
              label="Male"
              name="gender"
              value="male"
              checked={radio === "male"}
              onChange={(e) => {
                setRadio(e.target.value);
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12" className="mb-5">
            <MDBRow className="mb-1">
              <MDBCol>
                <MDBBox tag="div" className="tab vertical-tab mr-3">
                  Vertical Tab Left
                </MDBBox>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-1">
              <MDBCol>
                <MDBBox tag="div" className="tab active vertical-tab mr-3">
                  Vertical Tab Left
                </MDBBox>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBBox tag="div" className="tab vertical-tab mr-3">
                  Vertical Tab Left
                </MDBBox>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol>
            <MDBRow>
              <MDBCol>
                <MDBBox tag="div" className="tab horisontal-tab mr-4">
                  Horisontal Tab Bot
                </MDBBox>
                <MDBBox tag="div" className="tab active horisontal-tab mr-4">
                  Horisontal Tab Bot
                </MDBBox>
                <MDBBox tag="div" className="tab horisontal-tab mr-4">
                  Horisontal Tab Bot
                </MDBBox>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          {/* @ts-ignore */}
          <ProductWidget className="m-3" />
          {/* @ts-ignore */}
          <ProductWidget className="m-3" />
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBBox className="separator" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            {/* @ts-ignore */}
            <Pagination currentPage={1} totalPages={9} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            {/* @ts-ignore */}
            <Pagination currentPage={2} totalPages={9} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            {/* @ts-ignore */}
            <Pagination currentPage={3} totalPages={9} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            {/* @ts-ignore */}
            <Pagination currentPage={5} totalPages={9} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            {/* @ts-ignore */}
            <Pagination currentPage={7} totalPages={9} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            {/* @ts-ignore */}
            <Pagination currentPage={8} totalPages={9} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            {/* @ts-ignore */}
            <Pagination currentPage={9} totalPages={9} />
          </MDBCol>
        </MDBRow>
        <MDBBox className="mt-5">
          <Steps step={1} setStep={() => {}} validateStep={() => {}} />
        </MDBBox>
        <MDBRow>
          <MDBCol>
            <MDBListGroup>
              <MDBListGroupItem>Cras justo odio</MDBListGroupItem>
              <MDBListGroupItem>Dapibus ac facilisis in</MDBListGroupItem>
              <MDBListGroupItem>Morbi leo risus</MDBListGroupItem>
              <MDBListGroupItem>Porta ac consectetur ac</MDBListGroupItem>
              <MDBListGroupItem>Vestibulum at eros</MDBListGroupItem>
            </MDBListGroup>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default Typography;
