/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useState,
  useMemo,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Slider from "react-slick";
import { useOnClickOutside } from "../../../../../../utils";
import fabrics from "generation/fabrics";
import finishes from "generation/finishes";
import ItemContainer from "./components/itemContainer";
import sidebarReducer from "./sidebarReducer";
import { sliderSettings } from "./sliderSettings";
import {
  fabricArray,
  baseFinishArray,
  frameNailSizeArray,
} from "../../../../../../../Custom/haven/data";
import { MDBInput } from "mdbreact";
import SelectedOption from "../selectedOption";
import Icon from "app/assets/icon/icon";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";

const hardcodedMultiFilters = [
  { id: "ffifabrics_collection" },
  { id: "ffifabrics_grade" },
  { id: "ffifabrics_category1" },
  { id: "ffifabrics_finishtype" },
  { id: "ffifabrics_hexclrgrp" },
];

const initialSidebarState = {
  selectedCategory: null,
  fabricPage: 1,
  selectedFilters: {
    ffifabrics_collection: {
      active: false,
      label: "Collection",
      options: [],
      selectedOptions: [],
    },
    ffifabrics_grade: {
      active: false,
      label: "Grade",
      options: [],
      selectedOptions: [],
    },
    ffifabrics_category1: {
      active: false,
      label: "Pattern",
      options: [],
      selectedOptions: [],
    },
    ffifabrics_finishtype: {
      active: false,
      label: "Finish",
      options: [],
      selectedOptions: [],
    },
    ffifabrics_hexclrgrp: {
      active: false,
      label: "Color",
      options: [],
      selectedOptions: [],
    },
  },
};

const hardCodedCategories = [
  "Decorative Tape Frame",
  "Skirt Trim",
  "Base Trim",
  "Decorative Cord",
  "Throw Pillow Decorative Cord",
  "Kidney Pillow Decorative Cord",
  "Fringe",
  "Throw Pillow Fringe",
  "Kidney Pillow Fringe",
];

const hardCoddedFinishes = ["Nail Finish", "Nail Size"];

const Sidebar = ({
  visible,
  setVisible,
  option,
  selectedOption,
  optionDispatch,
  productImage,
  isCustom,
  player,
  optionState,
  isFinish,
  afterFirstOpenOptionState,
  sku,
  leatherAvailable,
  isPelleProvisions,
  finishOption,
  customName = "",
  isOutdoor = false,
  isTabView = false,
  saveSelectionFn,
}) => {
  let isWashable = useMemo(
    () => typeof customName === "string" && customName?.includes("Washable"),
    [customName]
  );

  let defaultFabric = useMemo(() => {
    if (option?.title === "Main Fabric") {
      let fabricIndex = fabrics?.findIndex((fabric) =>
        sku?.startsWith("L")
          ? fabric?.name === "Leather"
          : fabric.name === "Fabric"
      );
      let findDefault = fabrics?.[
        fabricIndex
      ]?.products?.items?.find((fabric) =>
        sku?.startsWith("L") ? fabric?.name === "Col" : fabric?.name === "Com"
      );
      return findDefault;
    }
    return null;
  }, [fabrics, option, sku]);

  const clickOutsideRef = useRef(null);
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  const isLoggedIn = useSelector(selectIsLoggedIn);
  useOnClickOutside(clickOutsideRef, setVisible);
  const [sidebarState, sidebarDispatch] = useReducer(
    sidebarReducer,
    initialSidebarState
  );

  const [resetSelectOptions, setResetSelectOptions] = useState(false);

  const [stockToggle, setStockToggle] = useState(false);

  const [searchVal, setSearchVal] = useState("");
  const [filtersShow, setFiltersShow] = useState(true);
  const [isLeatherProduct, setIsLeatherProduct] = useState(false);

  useEffect(() => {
    searchVal &&
      sidebarDispatch({
        type: "setSelectedCategory",
        category: sidebarState.selectedCategory,
      });
  }, [searchVal]);

  useEffect(() => setIsLeatherProduct(sku?.charAt(0)?.toLowerCase() === "l"), [
    sku,
  ]);

  useEffect(() => {
    //Garbage collection
    if (!visible) setSearchVal("");
  }, [visible]);
  const availableCategories = useMemo(() => {
    const fabricCategories = fabrics.filter(
      (category) =>
        category.jola_product_count > 0 &&
        ![8344, 8349].includes(category.id) &&
        showCategory(category.name)
    );

    const finishCategories = finishes.filter((category) =>
      finishOption === "*M"
        ? category.name.toLowerCase().includes("monogram") &&
          !category.name.toLowerCase().includes("nail")
        : !category.name.toLowerCase().includes("monogram") &&
          !category.name.toLowerCase().includes("nail")
    );

    return option?.title.toLowerCase().includes("finish")
      ? finishCategories
      : fabricCategories;
  }, [fabrics, finishes, finishOption, option, showCategory]);
  let availableCategoriesMemo = useMemo(() => {
    if (availableCategories?.length) {
      let arr = [
        ...availableCategories.reduce((acc, curr) => {
          return [...acc, { value: curr.id, label: curr.name }];
        }, []),
      ];
      if (availableCategories?.length > 1)
        arr.unshift({ value: "", label: "All" });
      return arr;
    }
  }, [availableCategories]);
  const selectCategory = useCallback(
    (categoryId) => {
      sidebarDispatch({
        type: "setSelectedCategory",
        category: categoryId,
      });
      sidebarDispatch({
        type: "resetFilterOptions",
      });

      let aggregations = !categoryId
        ? availableCategories[0]?.products?.aggregations
        : availableCategories.find(
            (fabricCategory) => categoryId === fabricCategory.id
          )?.products.aggregations;
      if (aggregations)
        aggregations
          .filter((filter) =>
            hardcodedMultiFilters.some((f) => f.id === filter.attribute_code)
          )
          .forEach((aggregation) => {
            sidebarDispatch({
              type: "setFilterOptions",
              filterId: aggregation.attribute_code,
              options: aggregation.options.filter(
                (option) => option?.count > 0
              ),
            });
          });
    },
    [availableCategories]
  );

  function showCategory(categoryName) {
    if (option?.title?.toLowerCase() === "finish") {
      return true;
    }
    if (isLeatherProduct && categoryName === "Leather") return true;
    else if (!isLeatherProduct) {
      if (categoryName !== "Leather") return true;
      else if (leatherAvailable === 1) return true;
    }
    return false;
  }
  function showFilterOption(filterOptionValue, filterKey) {
    let isShown = true;
    let activeFilter = null;
    let filterKeys = Object.keys(sidebarState?.selectedFilters);
    filterKeys.forEach((filterKey1) => {
      if (sidebarState.selectedFilters[filterKey1]?.active)
        activeFilter = filterKey1;
    });

    let searchFilter = filterKey ? filterKey : activeFilter;
    if (searchFilter === "ffifabrics_collection") searchFilter = "collection";
    else if (searchFilter === "ffifabrics_category1")
      searchFilter = "category1";
    else if (searchFilter === "ffifabrics_hexclrgrp") searchFilter = "color";
    else if (searchFilter === "ffifabrics_finishtype")
      searchFilter = "finishtype";
    else if (searchFilter === "ffifabrics_grade") searchFilter = "gradeFilter";
    let selectedCategory = availableCategories.find(
      (category) => category.id === sidebarState.selectedCategory
    );
    let possibleItems = selectedCategory?.products?.items;
    possibleItems = applyFilters(
      possibleItems,
      filterKey ? filterKey : activeFilter
    );
    isShown = possibleItems?.some((item) => {
      if (Array.isArray(selectedCategory?.products?.items[0][searchFilter])) {
        return item[searchFilter].some((filterOptions) => {
          return filterOptions === filterOptionValue.label;
        });
      } else {
        if (searchFilter === "collection" || searchFilter === "category1") {
          return item[searchFilter]?.split(",").some((filterOptions) => {
            return filterOptions === filterOptionValue.value;
          });
        } else return item[searchFilter] === filterOptionValue.label;
      }
    });
    return isShown;
  }

  useEffect(() => {
    const optionId = sidebarState.selectedFilters[
      "ffifabrics_collection"
    ]?.options?.find((opt) => opt?.label?.includes("Washable"))?.value;
    if (
      optionId &&
      isWashable &&
      !sidebarState?.selectedFilters["ffifabrics_collection"]?.selectedOptions
        ?.length
    )
      sidebarDispatch({
        type: "toggleFilterSelectedOption",
        filterId: "ffifabrics_collection",
        optionId,
      });
  }, [isWashable, sidebarState, selectCategory]);

  function showFilter(filterKey) {
    let isShown = true;
    let optionResults = [];
    sidebarState.selectedFilters[filterKey].options.forEach((filter) => {
      optionResults.push(showFilterOption(filter, filterKey));
    });
    isShown = optionResults.some((result) => result === true) ? true : false;
    return isShown;
  }

  function applyFilters(items, activeFilter) {
    let filteredItems = items?.filter(
      (fabric) =>
        (isPelleProvisions
          ? true
          : fabric.collection
          ? fabric.collection
              .split(",")
              //If its not pelle product filter out fabrics that are pelle collection
              .some((collectionId) => !pelleId || collectionId !== pelleId)
          : true) &&
        (sidebarState.selectedFilters["ffifabrics_collection"].selectedOptions
          ?.length > 0 && activeFilter !== "ffifabrics_collection"
          ? fabric.collection
            ? fabric.collection
                .split(",")
                .some((fabricCollectionId) =>
                  sidebarState.selectedFilters[
                    "ffifabrics_collection"
                  ].selectedOptions.some(
                    (filteredCollectionId) =>
                      filteredCollectionId === fabricCollectionId
                  )
                )
            : false
          : true) &&
        (sidebarState.selectedFilters["ffifabrics_category1"].selectedOptions
          ?.length > 0 && activeFilter !== "ffifabrics_category1"
          ? fabric.category1
            ? sidebarState.selectedFilters[
                "ffifabrics_category1"
              ].selectedOptions.some(
                (filteredPatternId) => filteredPatternId === fabric.category1
              )
            : false
          : true) &&
        (sidebarState.selectedFilters["ffifabrics_finishtype"].selectedOptions
          ?.length > 0 && activeFilter !== "ffifabrics_finishtype"
          ? fabric.finishtype
            ? fabric.finishtype.some((fabricFinishType) =>
                sidebarState.selectedFilters[
                  "ffifabrics_finishtype"
                ].selectedOptions.some(
                  (filteredFinishType) =>
                    filteredFinishType === fabricFinishType
                )
              )
            : false
          : true) &&
        (sidebarState.selectedFilters["ffifabrics_hexclrgrp"].selectedOptions
          ?.length > 0 && activeFilter !== "ffifabrics_hexclrgrp"
          ? fabric.color
            ? fabric.color.some((fabricColorId) =>
                sidebarState.selectedFilters[
                  "ffifabrics_hexclrgrp"
                ].selectedOptions.some(
                  (filteredColorId) => filteredColorId === fabricColorId
                )
              )
            : false
          : true) &&
        (sidebarState.selectedFilters["ffifabrics_grade"].selectedOptions
          ?.length > 0 && activeFilter !== "ffifabrics_grade"
          ? fabric.gradeFilter
            ? fabric.gradeFilter.some((fabricGradeId) =>
                sidebarState.selectedFilters[
                  "ffifabrics_grade"
                ].selectedOptions.some(
                  (filteredGradeId) => filteredGradeId === fabricGradeId
                )
              )
            : false
          : true) &&
        (!stockToggle ? true : fabric?.stockQty > 0)
    );
    return filteredItems;
  }

  useEffect(
    () =>
      selectCategory(
        availableCategories.find((fabricCategory) =>
          selectedOption?.categories?.some((c) => c.id === fabricCategory.id)
        )?.id ||
          (availableCategories?.length === 1 && availableCategories[0].id)
      ),
    [option]
  );
  const resetFn = useCallback(() => {
    let initValue =
      afterFirstOpenOptionState?.[
        option?.title?.toLowerCase()?.split(" ")?.join("_")
      ];

    if (
      initValue === selectedOption?.sku ||
      initValue === selectedOption?.id ||
      (initValue && !selectedOption)
    )
      return;
    setSearchVal("");
    setStockToggle(false);
    availableCategories?.length === 1 &&
      selectCategory(availableCategories[0].id);
    setResetSelectOptions(true);
    const checkedItem = document.querySelector(
      ".sidebar-item-container.checked"
    );
    if (option?.title?.toLowerCase()?.includes("nail") && checkedItem) {
      checkedItem.click();
    } else {
      optionDispatch(
        option?.title.toLowerCase().split(" ").join("_") === "main_fabric"
          ? {
              type: "setMainFabric",
              payload: initValue,
            }
          : optionState[option?.title.toLowerCase().split(" ").join("_")] ===
              initValue ||
            optionState[option?.title.toLowerCase().split(" ").join("_")] ===
              initValue
          ? {
              type: "clearAttribute",
              attribute: option?.title.toLowerCase().split(" ").join("_"),
            }
          : {
              type: "setAttribute",
              attribute: option?.title.toLowerCase().split(" ").join("_"),
              payload: initValue ?? false,
            }
      );
    }
    setTimeout(() => setResetSelectOptions(false), 0);
  }, [availableCategories, selectedOption, optionState]);
  /**
   * Removes Large Nail size option
   */
  const filteredOptions =
    option?.title === "Nail Size"
      ? option?.value?.filter((option) => option?.title !== "Large")
      : option?.value;

  const pelleId = useMemo(() => {
    let foundId = null;
    let leatherCategory = availableCategories.find(
      (category) => category?.name === "Leather"
    );
    if (leatherCategory && leatherCategory?.products?.aggregations) {
      let aggregations = leatherCategory?.products?.aggregations.find(
        (aggregation) => aggregation?.label === "ffifabrics_collection"
      );
      if (aggregations) {
        let pelleOption = aggregations?.options?.find(
          (option) => option?.label?.toLowerCase() === "pelle provisions"
        );
        if (pelleOption) foundId = pelleOption.value;
      }
    }
    if (foundId) {
      return foundId.toString();
    }
    return foundId;
  }, [availableCategories]);

  const getItemsFromAvailableCategories = (categories) => {
    let arr = [];
    categories.forEach((cat) => arr.push(cat?.products?.items));
    return sortAndFilterFabrics(
      arr
        .reduce((arr, val) => arr.concat(val), [])
        .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
    );
  };

  const sortAndFilterFabrics = (arr) =>
    arr
      ?.sort((a, b) => {
        return !!a.gradeFilter && !!b.gradeFilter
          ? a.gradeFilter[0] > b.gradeFilter[0]
            ? 1
            : b.gradeFilter[0] > a.gradeFilter[0]
            ? -1
            : 0
          : 0;
      })
      .sort((a, b) => {
        return !!a.gradeFilter && !!b.gradeFilter
          ? a.gradeFilter[0] === b.gradeFilter[0]
            ? a.name > b.name
              ? 1
              : b.name > a.name
              ? -1
              : 0
            : 0
          : 0;
      })
      .sort((a, b) => {
        return fabricArray.find((fabric) => fabric.id === a.sku) &&
          !fabricArray.find((fabric) => fabric.id === b.sku)
          ? -1
          : fabricArray.find((fabric) => fabric.id === a.sku) &&
            fabricArray.find((fabric) => fabric.id === b.sku)
          ? 0
          : 1;
      })
      .sort((a, b) => {
        if (option?.title === "Finish")
          return baseFinishArray.find((fabric) => fabric.id === a.sku) &&
            !baseFinishArray.find((fabric) => fabric.id === b.sku)
            ? -1
            : baseFinishArray.find((fabric) => fabric.id === a.sku) &&
              baseFinishArray.find((fabric) => fabric.id === b.sku)
            ? 0
            : 1;
      })
      .filter((fabric) =>
        searchVal.length > 0
          ? fabric.sku.toLowerCase().indexOf(searchVal?.toLowerCase()) !== -1 ||
            fabric.name.toLowerCase().indexOf(searchVal?.toLowerCase()) !== -1
          : true
      );
  // .slice(0, sidebarState.fabricPage * 100);

  const viewableFabrics = (() => {
    if (option?.title === "Nail Size") {
      return filteredOptions.map((val) => {
        const nailSize = frameNailSizeArray.find(
          (nailSize) => nailSize.name === val.title
        );
        return {
          name: nailSize.name,
          id: val.option_type_id,
          image: { url: nailSize.icon },
          grade: ["false"],
          pattern: ["false"],
          map: nailSize.map,
          icon: nailSize.icon,
          size: nailSize.size,
        };
      });
    }

    if (hardCoddedFinishes.includes(option?.title)) {
      return finishes.find((finish) => finish.name === option?.title)?.products
        .items;
    }

    if (hardCodedCategories.includes(option?.title)) {
      return fabrics.find((fabric) => fabric.name === option?.title)?.products
        .items;
    }

    if (!sidebarState.selectedCategory) {
      if (option?.title?.trim() === "Finish") {
        return (
          finishes
            ?.find((finish) => finish?.name === "Custom")
            ?.products?.items?.filter(
              (finish) =>
                finish?.name
                  ?.toLowerCase()
                  ?.includes(searchVal?.toLowerCase()) ||
                finish?.sku?.toLowerCase()?.includes(searchVal?.toLowerCase())
            ) || []
        );
      }

      return availableCategories?.length
        ? getItemsFromAvailableCategories(availableCategories)
        : [];
    }

    const currentCategory = availableCategories.find(
      (fabricCategory) => sidebarState.selectedCategory === fabricCategory.id
    );
    console.log();
    return sortAndFilterFabrics(
      currentCategory?.products?.items?.filter((fabric) => {
        const collectionValid =
          isPelleProvisions ||
          !fabric.collection ||
          !fabric.collection
            .split(",")
            .some((collectionId) => pelleId && collectionId === pelleId);

        const collectionFilterValid = sidebarState.selectedFilters[
          "ffifabrics_collection"
        ].selectedOptions?.length
          ? fabric.collection
              ?.split(",")
              .some((fabricCollectionId) =>
                sidebarState.selectedFilters[
                  "ffifabrics_collection"
                ].selectedOptions.includes(fabricCollectionId)
              )
          : true;

        const patternFilterValid = sidebarState.selectedFilters[
          "ffifabrics_category1"
        ].selectedOptions?.length
          ? sidebarState.selectedFilters[
              "ffifabrics_category1"
            ].selectedOptions.includes(fabric.category1)
          : true;

        const colorFilterValid = sidebarState.selectedFilters[
          "ffifabrics_hexclrgrp"
        ].selectedOptions?.length
          ? fabric.color?.some((fabricColorId) =>
              sidebarState.selectedFilters[
                "ffifabrics_hexclrgrp"
              ].selectedOptions.includes(fabricColorId)
            )
          : true;

        const finishTypeFilterValid = sidebarState.selectedFilters[
          "ffifabrics_finishtype"
        ].selectedOptions?.length
          ? fabric.finishtype?.some((fabricFinishType) =>
              sidebarState.selectedFilters[
                "ffifabrics_finishtype"
              ].selectedOptions.includes(fabricFinishType)
            )
          : true;

        const gradeFilterValid = sidebarState.selectedFilters[
          "ffifabrics_grade"
        ].selectedOptions?.length
          ? fabric.gradeFilter?.some((fabricGradeId) =>
              sidebarState.selectedFilters[
                "ffifabrics_grade"
              ].selectedOptions.includes(fabricGradeId)
            )
          : true;
        console.log(sidebarState, fabric?.gradeFilter);
        return (
          collectionValid &&
          collectionFilterValid &&
          patternFilterValid &&
          colorFilterValid &&
          finishTypeFilterValid &&
          gradeFilterValid
        );
      })
    );
  })();

  useEffect(() => {
    if (sidebarState.fabricPage === 0) {
      sidebarDispatch({ type: "nextFabricPage" });
    }
  }, [sidebarState.fabricPage]);

  const filteredFabrics = useMemo(() => {
    let arr = viewableFabrics ? [...viewableFabrics] : [];
    if (defaultFabric) {
      arr = arr.filter((fabric) => fabric.name !== defaultFabric?.name);
      arr.unshift(defaultFabric);
    }
    if (stockToggle) {
      return arr.filter(
        (fabric) => fabric?.stockQty && Number(fabric?.stockQty) > 0
      );
    } else return arr;
  }, [
    stockToggle,
    sidebarState?.selectedCategory,
    viewableFabrics,
    defaultFabric,
  ]);

  const [resetSidebarFabrics, setResetSidebarFabrics] = useState(false);

  useEffect(() => {
    sidebarDispatch({
      type: "resetFilterOptions",
    });
    if (sidebarState?.selectedCategory) {
      availableCategories
        .find(
          (fabricCategory) =>
            sidebarState?.selectedCategory === fabricCategory.id
        )
        .products.aggregations.filter((filter) =>
          hardcodedMultiFilters.some((f) => f.id === filter.attribute_code)
        )
        .forEach((aggregation) => {
          sidebarDispatch({
            type: "setFilterOptions",
            filterId: aggregation.attribute_code,
            options: aggregation.options.filter((option) => option?.count > 0),
          });
        });
    }
    setResetSidebarFabrics(true);
    setTimeout(() => setResetSidebarFabrics(false), 0);
  }, [stockToggle]);

  useEffect(() => {
    if (selectedOption?.categories) {
      let x = selectedOption.categories.find((category) => {
        let found;
        availableCategories.forEach((availableCategory) => {
          if (category.id === availableCategory.id) {
            found = true;
          }
        });
        return found ? category : undefined;
      });

      if (x) selectCategory(x.id);
    }
    // else if (visible) selectCategory(availableCategories[0]?.id);
  }, [visible]);

  const finalFabricsSort = useCallback(
    (fabrics) => {
      if (!fabrics?.length) return [];
      let commonElements = [...fabrics].filter((value) =>
        fabricArray.some((el) => value?.sku?.includes(el?.id))
      );
      let restFabrics = [...fabrics]?.filter((value) =>
        fabricArray.find((el) => !value?.sku?.includes(el?.id))
      );
      const finalArray = [...commonElements, ...restFabrics];
      if (defaultFabric) finalArray.unshift(defaultFabric);
      return finalArray.filter(
        (v, i, a) =>
          a.findIndex((v2) => v.sku === v2.sku && v.name === v2.name) === i
      );
    },
    [defaultFabric]
  );

  const filteredFabricsMemo = useMemo(() => {
    return finalFabricsSort(
      filteredFabrics?.filter((fabric) => {
        if (!fabric?.name?.toLowerCase()?.includes("-mg")) {
          if (isWashable) {
            if (fabric?.collection_label === customName) return fabric;
          } else return fabric;
        }
      })
    )
      ?.filter((el) =>
        isOutdoor
          ? el?.sku?.includes("3518-70") ||
            el?.sku?.includes("3518-61") ||
            el?.sku?.includes("3518-90")
          : el
      )
      ?.slice(0, sidebarState.fabricPage * 100);
  }, [
    filteredFabrics,
    isWashable,
    customName,
    isOutdoor,
    sidebarState.fabricPage,
    finalFabricsSort,
  ]);

  const fabricSkuRemoveFabricWord = (sku) =>
    sku?.includes("fabirc") ? sku?.replace("fabric-", "") : sku;

  const options = useMemo(() => {
    const activeFilterKey = Object.keys(sidebarState.selectedFilters).find(
      (key) => sidebarState.selectedFilters[key].active
    );

    if (!activeFilterKey) {
      return [];
    }

    const filteredOptions = sidebarState.selectedFilters[
      activeFilterKey
    ]?.options?.filter((opt) => {
      if (
        isWashable &&
        sidebarState?.selectedFilters?.["ffifabrics_collection"]?.active
      ) {
        if (opt?.label === customName) return opt;
      } else return opt;
    });

    if (!filteredOptions) {
      return [];
    }

    return filteredOptions
      .sort((oldVal, newVal) => {
        if (!oldVal?.label || !newVal?.label) {
          return 0;
        }

        return oldVal.label < newVal.label
          ? -1
          : oldVal.label > newVal.label
          ? 1
          : 0;
      })
      .map((option) => {
        const optionValue =
          sidebarState.selectedFilters[
            Object.keys(sidebarState.selectedFilters).find(
              (key) => sidebarState.selectedFilters[key].active
            )
          ].label === "Grade" ||
          sidebarState.selectedFilters[
            Object.keys(sidebarState.selectedFilters).find(
              (key) => sidebarState.selectedFilters[key].active
            )
          ].label === "Color" ||
          sidebarState.selectedFilters[
            Object.keys(sidebarState.selectedFilters).find(
              (key) => sidebarState.selectedFilters[key].active
            )
          ].label === "Finish"
            ? option?.label
            : option?.value;

        if (!optionValue) {
          return null;
        }

        return (
          <div
            className={"filter-option"}
            onClick={() => {
              sidebarDispatch({
                type: "toggleFilterSelectedOption",
                filterId: activeFilterKey,
                optionId: optionValue,
              });
            }}
            key={optionValue}
          >
            <div className={"checkbox"}>
              {sidebarState.selectedFilters[
                activeFilterKey
              ].selectedOptions.find((val) => val === optionValue) && (
                <Icon icon={"checked"} />
              )}
            </div>
            <p>{option?.label}</p>
          </div>
        );
      });
  }, [isWashable, customName, sidebarState, sidebarDispatch]);

  if (!visible) {
    return <></>;
  }

  return (
    <>
      {visible && (
        <div
          className={`sidebar-container ${isTabView && "tab-view-sidebar"}`}
          ref={clickOutsideRef}
        >
          <div className={"sidebar-title-section"}>
            <Icon
              icon={"closeWhite"}
              onClick={() => {
                setVisible(false);
              }}
            />
            <h1>Select {option?.title}</h1>
          </div>
          <div
            className={`scrollable-content ${
              isFinish && stockToggle && "finish-toggled"
            }`}
            id={"scrollableContent"}
          >
            {/*{isCustom ? (*/}
            {/*  generate3DImage(player).then((data) => {*/}
            {/*    return <ProductImage productImage={{ url: data }} />;*/}
            {/*  })*/}
            {/*) : (*/}
            {/* <ProductImage productImage={productImage} /> */}
            {!isTabView && (
              <SelectedOption
                option={selectedOption}
                isLoggedIn={isLoggedIn}
                optionTitle={option?.title}
              />
            )}

            {/*)}*/}
            {((!hardCodedCategories.find(
              (category) => category === option?.title
            ) &&
              !hardCoddedFinishes.find((finish) => finish === option?.title)) ||
              (option?.title.toLowerCase().indexOf("finish") !== -1 &&
                !hardCoddedFinishes.find(
                  (finish) => finish === option?.title
                ))) && (
              <>
                {/* <div className="container">
                  <div
                    className="filters-sidebar"
                    onClick={() => setFiltersShow(!filtersShow)}
                  >
                    <Icon
                      icon={`${filtersShow ? "filter-icon" : "filterOff"}`}
                    />
                    <p>Filters</p>
                  </div>
                </div> */}
                {
                  // filtersShow ? (
                  //   <div className={"available-categories"}>
                  //     {!resetSelectOptions ? (
                  //       <Select
                  //         placeholder="Select Type"
                  //         options={availableCategoriesMemo}
                  //         onChange={(e) => selectCategory(e.value)}
                  //         wrapperClass={`my-4`}
                  //         isSearchable={false}
                  //       />
                  //     ) : null}
                  //     {option?.title !== "Finish" ? (
                  //       <Checkbox
                  //         containerClass={"checkbox-container check-button"}
                  //         label={"In Stock"}
                  //         type={"checkbox"}
                  //         value={stockToggle}
                  //         checked={stockToggle}
                  //         onChange={(e) => setStockToggle(!stockToggle)}
                  //         wrapperClass={"checkmark gray"}
                  //       />
                  //     ) : null}
                  //   </div>
                  // ) : (
                  //   <></>
                  // )
                  // isMobileOrTablet && filtersShow ? (
                  //   <FilterModal
                  //     isOpen={filtersShow}
                  //     toggle={() => setFiltersShow(false)}
                  //     availableCategoriesMemo={availableCategoriesMemo}
                  //     selectCategory={selectCategory}
                  //     setStockToggle={setStockToggle}
                  //     stockToggle={stockToggle}
                  //     optionTitle={option?.title}
                  //   />
                  // ) : null
                }
              </>
            )}
            <div>
              <div className={`${isTabView && "tab-view-filters"}`}>
                <div className="container">
                  {!hardCodedCategories.find(
                    (category) => category === option?.title
                  ) &&
                    !hardCoddedFinishes.find(
                      (finish) => finish === option?.title
                    ) && (
                      <MDBInput
                        className="search-input"
                        hint={"Enter a Fabric/Finish SKU or Name"}
                        value={searchVal}
                        onChange={(e) => setSearchVal(e.target.value)}
                      >
                        <Icon icon="searchGray" />
                      </MDBInput>
                    )}
                </div>
                {!hardCodedCategories.find(
                  (category) => category === option?.title
                ) &&
                  !hardCoddedFinishes.find(
                    (finish) => finish === option?.title
                  ) &&
                  option?.title.toLowerCase().indexOf("finish") === -1 &&
                  ((filtersShow && !isMobileOrTablet) || isMobileOrTablet) && (
                    <>
                      <div className={"filter-container"}>
                        <div className={"filter-selector"}>
                          {Object.keys(sidebarState.selectedFilters).map(
                            (key) => {
                              if (key === "ffifabrics_finishtype") return null;
                              return (
                                showFilter(key) && (
                                  <div
                                    className={`filter-type ${
                                      sidebarState.selectedFilters[key].active
                                        ? "selected"
                                        : ""
                                    }`}
                                    key={key}
                                    onClick={() => {
                                      sidebarDispatch({
                                        type: "setFilterState",
                                        filterId: key,
                                        filterState: !sidebarState
                                          .selectedFilters[key].active,
                                      });
                                    }}
                                  >
                                    {sidebarState.selectedFilters[key].label}
                                    <div
                                      className={`toggle-arrow ${
                                        sidebarState.selectedFilters[key].active
                                          ? "selected"
                                          : ""
                                      }`}
                                    />
                                    {/* {isTabView && <div>
                              {options}
                            </div>} */}
                                  </div>
                                )
                              );
                            }
                          )}
                        </div>
                      </div>
                    </>
                  )}
              </div>
              <div
                className={`available-filters ${
                  !Object.keys(sidebarState.selectedFilters).find(
                    (key) => sidebarState.selectedFilters[key].active
                  )
                    ? "hidden"
                    : ""
                }`}
              >
                {Object.keys(sidebarState.selectedFilters).find(
                  (key) => sidebarState.selectedFilters[key].active
                ) && (
                  <>
                    {!isTabView ? (
                      <Slider
                        {...sliderSettings(
                          sidebarState.selectedFilters[
                            Object.keys(sidebarState.selectedFilters).find(
                              (key) => sidebarState.selectedFilters[key].active
                            )
                          ].label
                            .toLowerCase()
                            .includes("pattern")
                        )}
                      >
                        {options}
                      </Slider>
                    ) : (
                      <>{options}</>
                    )}
                  </>
                )}
              </div>
            </div>
            <div>
              <InfiniteScroll
                dataLength={
                  viewableFabrics?.length ? viewableFabrics.length : []
                }
                pageStart={0}
                next={() => sidebarDispatch({ type: "nextFabricPage" })}
                hasMore={true}
                scrollableTarget="scrollableContent"
                loader={<div className="loader" key={0} />}
              >
                {!resetSidebarFabrics
                  ? filteredFabricsMemo?.map((fabric, index) => {
                      return (
                        <ItemContainer
                          fabric={fabric}
                          sku={sku}
                          key={fabric?.sku}
                          selectedOption={selectedOption}
                          title={option?.title}
                          isCustom={isCustom}
                          selectFabric={() => {
                            optionDispatch(
                              option?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_") === "main_fabric"
                                ? {
                                    type: "setMainFabric",
                                    payload: fabricSkuRemoveFabricWord(
                                      fabric.sku
                                    ),
                                  }
                                : optionState[
                                    option?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ] === fabricSkuRemoveFabricWord(fabric.sku) ||
                                  optionState[
                                    option?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ] === fabric.id
                                ? {
                                    type: "clearAttribute",
                                    attribute: option?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_"),
                                  }
                                : {
                                    type: "setAttribute",
                                    attribute: option?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_"),
                                    payload: fabric.sku
                                      ? fabricSkuRemoveFabricWord(fabric.sku)
                                      : fabric.id,
                                  }
                            );
                          }}
                          isFinish={
                            !(
                              !hardCodedCategories.find(
                                (category) => category === option?.title
                              ) &&
                              !hardCoddedFinishes.find(
                                (finish) => finish === option?.title
                              ) &&
                              option?.title.toLowerCase().indexOf("finish") ===
                                -1
                            )
                          }
                          indexInArray={index}
                        />
                      );
                    })
                  : null}
              </InfiniteScroll>
            </div>
          </div>
          {isTabView ? (
            <div className={"sidebar-footer-tab"}>
              {/* <div className="sidebar-footer-left">
              <img src={selectedOption?.image?.url} alt="" className="selected-option-image" />
              <div className={"selected-option-values"}>
                <div className={"selected-option"}>
                <p>Selected</p>
                <h4>{selectedOption?.name}</h4>
                </div>
              </div>
            </div> */}
              {selectedOption && (
                <SelectedOption
                  option={selectedOption}
                  isLoggedIn={isLoggedIn}
                  optionTitle={option?.title}
                  isTabView={isTabView}
                />
              )}

              <div className="sidebar-footer-actions">
                <div
                  className={"apply-button"}
                  onClick={() => {
                    saveSelectionFn();
                  }}
                >
                  <p>Save Selection</p>
                </div>
                <div className={"reset-btn"} onClick={resetFn}>
                  <p>Reset to Default</p>
                </div>
              </div>
            </div>
          ) : (
            <div className={"sidebar-footer"}>
              <div className={"selected-option"}>
                <p>Selected</p>
                <h4>{selectedOption?.name}</h4>
              </div>
              <div className="d-flex justify-content-between">
                <div className={"reset-btn"} onClick={resetFn}>
                  <p>Reset to Default</p>
                </div>
                <div
                  className={"apply-button"}
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  <p>Save Selection</p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Sidebar;
