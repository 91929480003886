/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-key */
import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from "react";
import useCustomer from "app/state/hooks/customer/useCustomer";
import { Link } from "react-router-dom";
import Selector from "./components/selector";
import Icon from "../../../../assets/icon/icon";
import IconContainer from "../iconContainer";
import Dropdown from "./components/dropdown";
import fabrics from "generation/fabrics";
import finishes from "generation/finishes";
// import CustomPriceContext from "../../customPriceContext";
import CheckboxSidebar from "./components/checkboxSidebar";
import ModalOptionSelection from "./components/modalOptionSelection";

let sortByOptions = [
  "upholstery_options",
  "pillow_upholstery_options",
  "nailhead_trim_options",
  "additional_options",
  "view_more_options",
];

const hardcodedUpholsteryOptions = [
  "main fabric",
  "inside back",
  "outside back",
  "cushion",
  "tight seat",
  "back pillow",
];

const Options = ({
  options,
  optionState,
  optionDispatch,
  productImage,
  isCustom = false,
  type,
  player,
  toggleFrameOptions = false,
  sku,
  leatherAvailable,
  isPelleProvisions,
  ffi_fullyuph = false,
  finishOption,
  byoSku = false,
  product,
  customName = "",
  finishError,
  setFinishError,
  frame = null,
  priceDispatch,
  isTabsView = false
}) => {

  const [checkboxVisible, setCheckboxVisible] = useState(false);
  const customer = useCustomer().isLoggedIn();
  const [afterFirstOpenOptionState, setAfterFirstOpenOptionState] = useState(
    null
  );

  // const [initialSelectedOption, setInitialSelectedOption] = useState(false);
  const [moreUpholstery, setMoreUpholstery] = useState(false);

  const optionsAnimation = (element) => {
    let selectionHolder = document.querySelector(element);
    selectionHolder.classList.remove("slide-in");
    selectionHolder.classList.add("slide-out");
  };

  const mappedOptions = options?.reduce(
    (accumulator, option) =>
      option?.category_options
        ? {
            ...accumulator,
            [option?.category_options.toLowerCase().split(" ").join("_")]: (
              accumulator[
                option?.category_options?.toLowerCase().split(" ").join("_")
              ] || []
            ).concat(option),
          }
        : accumulator,
    {}
  );
  console.log('mappedOptions', mappedOptions)

  const gradePriceList = useMemo(
    () =>
      options
        ?.find(
          (option) => option?.title?.toLowerCase() === "upholstery options"
        )
        ?.value.reduce(
          (gradePriceList, grade) => ({
            ...gradePriceList,
            [grade.title?.replace("grade", "")]: grade.price,
          }),
          {}
        ),
    [options]
  );

  useEffect(() => {
    const allFabrics = fabrics.reduce(
      (allFabricCategories, fabricCategory) =>
        allFabricCategories.concat(fabricCategory?.products?.items),
      []
    );
    const priceGrade = hardcodedUpholsteryOptions
      .map((option) => optionState[option?.split(" ").join("_")])
      .reduce(
        (highestGrade, sku) => {
          let fabric = allFabrics?.find((fabric) => fabric?.sku === sku);
          return fabric && fabric?.grade
            ? fabric.grade[0] &&
              gradePriceList?.[fabric?.grade[0].toLowerCase()] >
                gradePriceList?.[highestGrade.grade[0].toLowerCase()]
              ? fabric
              : highestGrade
            : highestGrade;
        },
        allFabrics?.find((fabric) => fabric?.sku === optionState.main_fabric)
      );
    priceDispatch({
      type: "setCustomizationOptionPrice",
      customizationOptionPrice:
        gradePriceList?.[priceGrade?.grade[0].toLowerCase()],
    });
    if (!!options) {
      optionDispatch({
        type: "setAttribute",
        attribute: "upholstery_options",
        payload: options
          ?.find(
            (option) =>
              option?.title?.toLowerCase().split(" ").join("_") ===
              "upholstery_options"
          )
          ?.value?.find(
            (val) =>
              val.title?.toLowerCase() ===
              `${
                priceGrade?.grade[0].startsWith("leather") ? "" : "grade"
              }${priceGrade?.grade[0].toLowerCase()}`
          )?.option_type_id,
      });
    }
  }, [gradePriceList, optionDispatch, optionState, options, priceDispatch]);
  useEffect(() => {
    optionDispatch({
      type: "setAttribute",
      attribute: "decorative_cord",
      payload: null,
    });
    optionDispatch({
      type: "setAttribute",
      attribute: "fringe",
      payload: null,
    });
  }, [optionState.main_fabric]);
  useEffect(() => {
    priceDispatch({
      type: "setContrastWeltPrice",
      contrastWeltPrice:
        !!optionState.contrasting_welt &&
        optionState.contrasting_welt !== optionState.main_fabric
          ? fabrics
              .reduce(
                (allFabricCategories, fabricCategory) =>
                  allFabricCategories.concat(fabricCategory.products.items),
                []
              )
              ?.find((fabric) => fabric.sku === optionState.contrasting_welt)
              .grade[0].startsWith("leather") //TODO: This checks whether the grade of selected option is leather or fabric. The grades might get updated from the backend and need to be fixed
            ? options?.find(
                (option) =>
                  option?.title?.toLowerCase().split(" ").join("_") ===
                  "leather_contrast_welt"
              )?.value[0].price
            : options?.find(
                (option) =>
                  option?.title?.toLowerCase().split(" ").join("_") ===
                  "fabric_contrast_welt"
              )?.value[0].price
          : 0,
    });
    if (
      optionState.contrasting_welt &&
      optionState.contrasting_welt !== optionState.main_fabric
    ) {
      optionDispatch({
        type: "setAttribute",
        attribute: "decorative_cord",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "fringe",
        payload: null,
      });
      const contrastWeltCheckbox = fabrics
        .reduce(
          (allFabricCategories, fabricCategory) =>
            allFabricCategories.concat(fabricCategory.products.items),
          []
        )
        ?.find((fabric) => fabric.sku === optionState.contrasting_welt)
        ?.grade[0].startsWith("leather")
        ? "leather_contrast_welt"
        : "fabric_contrast_welt";
      optionDispatch({
        type: "setAttribute",
        attribute:
          contrastWeltCheckbox === "leather_contrast_welt"
            ? "fabric_contrast_welt"
            : "leather_contrast_welt",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute:
          contrastWeltCheckbox === "leather_contrast_welt"
            ? "fabric_contrast_welt"
            : "leather_contrast_welt",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: contrastWeltCheckbox,
        payload: options?.find(
          (option) =>
            option?.title?.toLowerCase().split(" ").join("_") ===
            contrastWeltCheckbox
        )?.value[0].option_type_id,
      });
    } else {
      optionDispatch({
        type: "setAttribute",
        attribute: "fabric_contrast_welt",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "leather_contrast_welt",
        payload: null,
      });
    }
  }, [
    optionDispatch,
    optionState.contrasting_welt,
    optionState.main_fabric,
    options,
    priceDispatch,
  ]);
  useEffect(() => {
    priceDispatch({
      type: "setThrowPillowContrastWeltPrice",
      throwPillowContrastWeltPrice: optionState.throw_pillow_contrast_welt
        ? fabrics
            .reduce(
              (allFabricCategories, fabricCategory) =>
                allFabricCategories.concat(fabricCategory.products.items),
              []
            )
            ?.find(
              (fabric) => fabric.sku === optionState.throw_pillow_contrast_welt
            )
            ?.grade[0].startsWith("leather") //TODO: This checks whether the grade of selected option is leather or fabric. The grades might get updated from the backend and need to be fixed
          ? options?.find(
              (option) =>
                option?.title?.toLowerCase().split(" ").join("_") ===
                "throw_pillow_leather_contrast_welt_upcharge"
            )?.value[0].price
          : options?.find(
              (option) =>
                option?.title?.toLowerCase().split(" ").join("_") ===
                "throw_pillow_fabric_contrast_welt_upcharge"
            )?.value[0].price
        : 0,
    });
    if (optionState.throw_pillow_contrast_welt) {
      optionDispatch({
        type: "setAttribute",
        attribute: "throw_pillow_fringe",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "throw_pillow_decorative_cord",
        payload: null,
      });
      const contrastWeltCheckbox = fabrics
        .reduce(
          (allFabricCategories, fabricCategory) =>
            allFabricCategories.concat(fabricCategory.products.items),
          []
        )
        ?.find(
          (fabric) => fabric.sku === optionState.throw_pillow_contrast_welt
        )
        ?.grade[0].startsWith("leather")
        ? "throw_pillow_leather_contrast_welt_upcharge"
        : "throw_pillow_fabric_contrast_welt_upcharge";
      optionDispatch({
        type: "setAttribute",
        attribute:
          contrastWeltCheckbox === "throw_pillow_leather_contrast_welt_upcharge"
            ? "throw_pillow_fabric_contrast_welt_upcharge"
            : "throw_pillow_leather_contrast_welt_upcharge",
        payload: null,
      });

      optionDispatch({
        type: "setAttribute",
        attribute: contrastWeltCheckbox,
        payload: options?.find(
          (option) =>
            option?.title?.toLowerCase().split(" ").join("_") ===
            contrastWeltCheckbox
        )?.value[0].option_type_id,
      });
    } else {
      optionDispatch({
        type: "setAttribute",
        attribute: "throw_pillow_fabric_contrast_welt_upcharge",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "throw_pillow_leather_contrast_welt_upcharge",
        payload: null,
      });
    }
  }, [
    optionDispatch,
    optionState.throw_pillow_contrast_welt,
    options,
    priceDispatch,
  ]);
  useEffect(() => {
    priceDispatch({
      type: "setKidneyPillowContrastWeltPrice",
      kidneyPillowContrastWeltPrice: optionState.kidney_pillow_contrast_welt
        ? fabrics
            .reduce(
              (allFabricCategories, fabricCategory) =>
                allFabricCategories.concat(fabricCategory.products.items),
              []
            )
            ?.find(
              (fabric) => fabric.sku === optionState.kidney_pillow_contrast_welt
            )
            ?.grade[0].startsWith("leather") //TODO: This checks whether the grade of selected option is leather or fabric. The grades might get updated from the backend and need to be fixed
          ? options?.find(
              (option) =>
                option?.title?.toLowerCase().split(" ").join("_") ===
                "kidney_pillow_leather_contrast_welt_upcharge"
            )?.value[0].price
          : options?.find(
              (option) =>
                option?.title?.toLowerCase().split(" ").join("_") ===
                "kidney_pillow_fabric_contrast_welt_upcharge"
            )?.value[0].price
        : 0,
    });
    if (optionState.kidney_pillow_contrast_welt) {
      optionDispatch({
        type: "setAttribute",
        attribute: "kidney_pillow_fringe",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "kidney_pillow_decorative_cord",
        payload: null,
      });
      const contrastWeltCheckbox = fabrics
        .reduce(
          (allFabricCategories, fabricCategory) =>
            allFabricCategories.concat(fabricCategory.products.items),
          []
        )
        ?.find(
          (fabric) => fabric.sku === optionState.kidney_pillow_contrast_welt
        )
        ?.grade[0].startsWith("leather")
        ? "kidney_pillow_leather_contrast_welt_upcharge"
        : "kidney_pillow_fabric_contrast_welt_upcharge";
      optionDispatch({
        type: "setAttribute",
        attribute:
          contrastWeltCheckbox ===
          "kidney_pillow_leather_contrast_welt_upcharge"
            ? "kidney_pillow_fabric_contrast_welt_upcharge"
            : "kidney_pillow_leather_contrast_welt_upcharge",
        payload: null,
      });

      optionDispatch({
        type: "setAttribute",
        attribute: contrastWeltCheckbox,
        payload: options?.find(
          (option) =>
            option?.title?.toLowerCase().split(" ").join("_") ===
            contrastWeltCheckbox
        )?.value[0].option_type_id,
      });
    } else {
      optionDispatch({
        type: "setAttribute",
        attribute: "kidney_pillow_fabric_contrast_welt_upcharge",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "kidney_pillow_leather_contrast_welt_upcharge",
        payload: null,
      });
    }
  }, [
    optionDispatch,
    optionState.kidney_pillow_contrast_welt,
    options,
    priceDispatch,
  ]);

  useEffect(() => {
    options &&
      options
        .filter(
          (option) =>
            ["Additional Options"].indexOf(option?.category_options) > -1
        )
        .forEach((option) => {
          if (
            !optionState.hasOwnProperty(
              option?.title?.toLowerCase().split(" ").join("_")
            ) ||
            !option?.value.some(
              (value) =>
                value.option_type_id ===
                optionState[option?.title?.toLowerCase().split(" ").join("_")]
            )
          ) {
            optionDispatch({
              type: "setAttribute",
              attribute: option?.title?.toLowerCase().split(" ").join("_"),
              payload:
                option?.value[0].price === 0
                  ? option?.value[0].option_type_id
                  : false,
            });
          }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
  useEffect(() => {
    options &&
      options
        .filter(
          (option) =>
            option?.title?.toLowerCase().split(" ").join("_") === "nail_size"
        )
        .forEach((option) => {
          if (!optionState.nail_size && optionState.nail_finish) {
            optionDispatch({
              type: "setAttribute",
              attribute: option?.title?.toLowerCase().split(" ").join("_"),
              payload:
                option?.value[0].price === 0 || optionState.nail_finish
                  ? option?.value[0].option_type_id
                  : false,
            });
          }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, optionState.nail_finish]);
  useEffect(() => {
    options &&
      options
        .filter(
          (option) =>
            option?.title?.toLowerCase().split(" ").join("_") === "nail_size"
        )
        .forEach((option) => {
          if (
            !optionState.nail_size ||
            !option?.value.some(
              (value) => value.option_type_id === optionState.nail_size
            )
          ) {
            optionDispatch({
              type: "setAttribute",
              attribute: option?.title?.toLowerCase().split(" ").join("_"),
              payload:
                option?.value[0].price === 0 || optionState.nail_finish
                  ? option?.value[0].option_type_id
                  : false,
            });
          }
        });
  }, [options]);
  useEffect(() => {
    if (!optionState.nail_finish && optionState.nail_size) {
      optionDispatch({
        type: "setAttribute",
        attribute: "nail_finish",
        payload: finishes?.find(
          (finish) =>
            finish.name.toLowerCase().split(" ").join("_") === "nail_finish"
        )?.products.items[0].sku,
      });
    }
  }, [optionState.nail_size, options]);

  useEffect(() => {
    if (!optionState.nail_size || !optionState.nail_finish) {
      optionDispatch({
        type: "setAttribute",
        attribute: "nail_band",
        payload: false,
      });
    }
  }, [optionState.nail_size, optionState.nail_finish]);

  useEffect(() => {
    priceDispatch({
      type: "setDropdownPrice",
      dropdownPrice:
        options &&
        options
          .filter((option) => option?.category_options === "Additional Options")
          .reduce(
            (dropdownPrice, option) =>
              dropdownPrice +
              (optionState.hasOwnProperty(
                option?.title?.toLowerCase().split(" ").join("_")
              )
                ? optionState[option?.title?.toLowerCase().split(" ").join("_")]
                  ? option?.value?.find(
                      (optionValue) =>
                        optionValue.option_type_id ===
                        optionState[
                          option?.title?.toLowerCase().split(" ").join("_")
                        ]
                    )?.price
                  : 0
                : 0),
            0
          ),
    });
  }, [optionState, options, priceDispatch]);
  useEffect(() => {
    priceDispatch({
      type: "setViewMorePdpPrice",
      viewMorePdpPrice:
        options &&
        options
          .filter(
            (option) =>
              option?.category_options === "View More Options" &&
              !["contrast welt", "fringe", "decorative cord"].some((title) =>
                option?.title?.toLowerCase().includes(title)
              )
          )
          .map(
            (viewMorePdpOption) =>
              optionState.hasOwnProperty(
                viewMorePdpOption.title?.toLowerCase().split(" ").join("_")
              )
                ? optionState[
                    viewMorePdpOption.title?.toLowerCase().split(" ").join("_")
                  ]
                  ? viewMorePdpOption.value[0].price
                  : 0
                : 0,
            0
          )
          .reduce((a, c) => a + c, 0),
    });
  }, [optionState, options, priceDispatch]);
  useEffect(() => {
    priceDispatch({
      type: "setNailPrice",
      nailPrice:
        (options &&
          options
            ?.find(
              (option) =>
                option?.title?.toLowerCase().split(" ").join("_") ===
                "nail_size"
            )
            ?.value?.find((val) => val.option_type_id === optionState.nail_size)
            ?.price) ||
        0,
    });
  }, [optionState.nail_size, options, priceDispatch]);
  useEffect(() => {
    priceDispatch({
      type: "setKidneyPillowFringePrice",
      kidneyPillowFringePrice: optionState.kidney_pillow_fringe
        ? options?.find(
            (option) =>
              option?.title?.toLowerCase().split(" ").join("_") ===
              "kidney_pillow_fringe_upcharge"
          )?.value[0].price
        : 0,
    });
    if (!!optionState.kidney_pillow_fringe) {
      optionDispatch({
        type: "setAttribute",
        attribute: "kidney_pillow_contrast_welt",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "kidney_pillow_decorative_cord",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "kidney_pillow_fringe_upcharge",
        payload: options?.find(
          (option) =>
            option?.title?.toLowerCase().split(" ").join("_") ===
            "kidney_pillow_fringe_upcharge"
        )?.value[0].option_type_id,
      });
    } else {
      optionDispatch({
        type: "setAttribute",
        attribute: "kidney_pillow_fringe_upcharge",
        payload: null,
      });
    }
  }, [
    optionDispatch,
    optionState.kidney_pillow_fringe,
    options,
    priceDispatch,
  ]);
  useEffect(() => {
    priceDispatch({
      type: "setKidneyPillowDecorativeCordPrice",
      kidneyPillowDecorativeCordPrice: optionState.kidney_pillow_decorative_cord
        ? options?.find(
            (option) =>
              option?.title?.toLowerCase().split(" ").join("_") ===
              "kidney_pillow_decorative_cord_upcharge"
          )?.value[0].price
        : 0,
    });
    if (!!optionState.kidney_pillow_decorative_cord) {
      optionDispatch({
        type: "setAttribute",
        attribute: "kidney_pillow_fringe",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "kidney_pillow_contrast_welt",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "kidney_pillow_decorative_cord_upcharge",
        payload: options?.find(
          (option) =>
            option?.title?.toLowerCase().split(" ").join("_") ===
            "kidney_pillow_decorative_cord_upcharge"
        )?.value[0].option_type_id,
      });
    } else {
      optionDispatch({
        type: "setAttribute",
        attribute: "kidney_pillow_decorative_cord_upcharge",
        payload: null,
      });
    }
  }, [
    optionDispatch,
    optionState.kidney_pillow_decorative_cord,
    options,
    priceDispatch,
  ]);
  useEffect(() => {
    priceDispatch({
      type: "setThrowPillowFringePrice",
      throwPillowFringePrice: optionState.throw_pillow_fringe
        ? options?.find(
            (option) =>
              option?.title?.toLowerCase().split(" ").join("_") ===
              "throw_pillow_fringe_upcharge"
          )?.value[0].price
        : 0,
    });
    if (!!optionState.throw_pillow_fringe) {
      optionDispatch({
        type: "setAttribute",
        attribute: "throw_pillow_contrast_welt",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "throw_pillow_decorative_cord",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "throw_pillow_fringe_upcharge",
        payload: options?.find(
          (option) =>
            option?.title?.toLowerCase().split(" ").join("_") ===
            "throw_pillow_fringe_upcharge"
        )?.value[0].option_type_id,
      });
    } else {
      optionDispatch({
        type: "setAttribute",
        attribute: "throw_pillow_fringe_upcharge",
        payload: null,
      });
    }
  }, [optionDispatch, optionState.throw_pillow_fringe, options, priceDispatch]);
  useEffect(() => {
    priceDispatch({
      type: "setThrowPillowDecorativeCordPrice",
      throwPillowDecorativeCordPrice: optionState.throw_pillow_decorative_cord
        ? options?.find(
            (option) =>
              option?.title?.toLowerCase().split(" ").join("_") ===
              "throw_pillow_decorative_cord_upcharge"
          )?.value[0].price
        : 0,
    });
    if (!!optionState.throw_pillow_decorative_cord) {
      optionDispatch({
        type: "setAttribute",
        attribute: "throw_pillow_fringe",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "throw_pillow_contrast_welt",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "throw_pillow_decorative_cord_upcharge",
        payload: options?.find(
          (option) =>
            option?.title?.toLowerCase().split(" ").join("_") ===
            "throw_pillow_decorative_cord_upcharge"
        )?.value[0].option_type_id,
      });
    } else {
      optionDispatch({
        type: "setAttribute",
        attribute: "throw_pillow_decorative_cord_upcharge",
        payload: null,
      });
    }
  }, [
    optionDispatch,
    optionState.throw_pillow_decorative_cord,
    options,
    priceDispatch,
  ]);
  useEffect(() => {
    if (optionState.fringe) {
      optionDispatch({
        type: "setAttribute",
        attribute: "decorative_cord",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "contrasting_welt",
        payload: null,
      });
    }
    priceDispatch({
      type: "setFringePrice",
      fringePrice: optionState.fringe
        ? options?.find(
            (option) =>
              option?.title?.toLowerCase().split(" ").join("_") === "fringe" &&
              option?.category_options === null
          )?.value[0].price || 0
        : 0,
    });
    if (!!optionState.fringe) {
      optionDispatch({
        type: "setAttribute",
        attribute: "fringe_upcharge",
        payload: options?.find(
          (option) =>
            option?.title?.toLowerCase().split(" ").join("_") === "fringe" &&
            option?.category_options === null
        )?.value[0].option_type_id,
      });
    } else {
      optionDispatch({
        type: "setAttribute",
        attribute: "fringe_upcharge",
        payload: null,
      });
    }
  }, [
    optionDispatch,
    optionState.fringe,
    optionState.main_fabric,
    options,
    priceDispatch,
  ]);
  useEffect(() => {
    if (optionState.decorative_cord) {
      optionDispatch({
        type: "setAttribute",
        attribute: "fringe",
        payload: null,
      });
      optionDispatch({
        type: "setAttribute",
        attribute: "contrasting_welt",
        payload: null,
      });
    }
    priceDispatch({
      type: "setDecorativeCordPrice",
      decorativeCordPrice: optionState.decorative_cord
        ? options?.find(
            (option) =>
              option?.title?.toLowerCase().split(" ").join("_") ===
                "decorative_cord" && option?.category_options === null
          )?.value[0].price || 0
        : 0,
    });
    if (!!optionState.decorative_cord) {
      optionDispatch({
        type: "setAttribute",
        attribute: "decorative_cord_upcharge",
        payload: options?.find(
          (option) =>
            option?.title?.toLowerCase().split(" ").join("_") ===
              "decorative_cord" && option?.category_options === null
        )?.value[0].option_type_id,
      });
    } else {
      optionDispatch({
        type: "setAttribute",
        attribute: "decorative_cord_upcharge",
        payload: null,
      });
    }
  }, [optionDispatch, optionState.decorative_cord, options, priceDispatch]);

  const [opened, setOpened] = useState({});
  const [optionSelected, setOptionSelected] = useState(isTabsView ? mappedOptions["upholstery_options"]
    ?.filter(
      (opt) => opt.title.toLowerCase() === "finish"
    )?.[0] : null);
  useEffect(() => {
    if (finishError) {
      setOpened(() => ({
        ...opened,
        ["finish"]: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishError]);
  const  [allCategoryOptions, setAllCategoryOptions] = useState();

  useEffect(() => {
    if (afterFirstOpenOptionState) return;
    if (
      Object.keys(opened).length === 1 &&
      opened["view_more_upholstery_options"]
    ) {
      setAfterFirstOpenOptionState(optionState);
    }
    return;
  }, [opened, optionState]);

  useEffect(() => {
    let rtrObj = {};

    mappedOptions &&
      Object.keys(mappedOptions).forEach((key, index) => {
        if(!isTabsView) {
           rtrObj[key] = key === "upholstery_options";
        }
        else {
          rtrObj[key] = false;
        }
        
      });

    rtrObj["view_more_upholstery_options"] = false;
    rtrObj["finish"] = true;
    setOpened(rtrObj);
  }, []);
  useEffect(() => {
    priceDispatch({
      type: "setSkirtTrimPrice",
      skirtTrimPrice: optionState.skirt_trim
        ? options?.find(
            (option) =>
              option?.title?.toLowerCase().split(" ").join("_") === "skirt_trim"
          )?.field_value.price
        : 0,
    });
  }, [optionState.skirt_trim, options, priceDispatch]);
  useEffect(() => {
    priceDispatch({
      type: "setBaseTrimPrice",
      baseTrimPrice: optionState.base_trim
        ? options?.find(
            (option) =>
              option?.title?.toLowerCase().split(" ").join("_") === "base_trim"
          )?.field_value.price
        : 0,
    });
  }, [optionState.base_trim, options, priceDispatch]);
  useEffect(() => {
    priceDispatch({
      type: "setDecorativeTapeFramePrice",
      decorativeTapeFramePrice: optionState.decorative_tape_frame
        ? options?.find(
            (option) =>
              option?.title?.toLowerCase().split(" ").join("_") ===
              "decorative_tape_frame"
          )?.field_value.price
        : 0,
    });
  }, [optionState.decorative_tape_frame, options, priceDispatch]);
  useEffect(() => {
    priceDispatch({
      type: "setNailBandPrice",
      nailBandPrice: optionState.nail_band
        ? options?.find(
            (option) =>
              option?.title?.toLowerCase().split(" ").join("_") === "nail_band"
          )?.field_value.price
        : 0,
    });
  }, [optionState.nail_band, options, priceDispatch]);

  const isOutdoor = useMemo(
    () =>
      product?.categories?.find((el) => el?.name?.toLowerCase() === "outdoor"),
    [product?.categories]
  );

  const checkForOutdoor = useCallback(
    (sku) =>
      isOutdoor &&
      !sku?.includes("3518-70") &&
      !sku?.includes("3518-61") &&
      !sku?.includes("3518-90"),
    [isOutdoor]
  );

  const sortedAndFilteredMappedOptions = useMemo(
    () =>
      {
        if(!mappedOptions) return [];
        
        return Object.keys(mappedOptions)
        .sort((a, b) => sortByOptions.indexOf(a) - sortByOptions.indexOf(b))
        .filter((option) => {
          const isBenchmadeOttomans = window.location.pathname.includes(
            "benchmade-ottomans"
          );
          return !(
            (byoSku?.[2] === "S" && byoSku?.[3] === "S") ||
            (isBenchmadeOttomans && byoSku?.[3] === "S")
          )
            ? option !== "view_more_options"
            : option !== "nailhead_trim_options" &&
                option !== "view_more_options";
        })},
    [mappedOptions, byoSku]
  );


  return (
    <>
      <CheckboxSidebar
        visible={checkboxVisible}
        setVisible={setCheckboxVisible}
        availableOptions={mappedOptions?.["view_more_options"]?.filter(
          (option) =>
            [
              "contrast welt",
              "carton",
              "cushion tie-down",
              "dual usb",
              "moist barrier - all seat",
              "moist barrier - seat/back/arms",
              "fringe",
              "decorative cord",
              "fabric contrast welt",
              "leather contrast welt",
              "contrasting buttons",
            ].indexOf(option?.title?.toLowerCase()) === -1
        )}
        optionState={optionState}
        optionDispatch={optionDispatch}
        frame={frame}
      />
      {ffi_fullyuph ? (
        <></>
      ) : (
        <div className={`options-container ${isTabsView ? "tabs-view" : ""}`}>
          {mappedOptions &&
            sortedAndFilteredMappedOptions
              .map((key) => {
                const isBenchmadeBenches = window.location.pathname.includes(
                  "benchmade-benches"
                );
                const isMonogramType = isCustom && type === "Monogram";
                const isSpecialSku = byoSku?.[3] === "S";

                const isFinishFound =
                  !isMonogramType &&
                  Array.isArray(mappedOptions[key]) &&
                  mappedOptions[key].some(
                    (option) => option?.title?.toLowerCase() === "finish"
                  );

                const finishFound =
                  isBenchmadeBenches && isSpecialSku ? null : isFinishFound;

                const moreUpholsteryOptions =
                  key === "upholstery_options"
                    ? Array.isArray(mappedOptions[key])
                      ? mappedOptions[key].filter(
                          (opt) =>
                            !["main fabric", "finish"].includes(
                              opt?.title?.toLowerCase()
                            )
                        )
                      : null
                    : null;

                const mainFabricOption =
                  key === "upholstery_options" &&
                  Array.isArray(mappedOptions[key]) &&
                  mappedOptions[key].find(
                    (opt) => opt?.title?.toLowerCase() === "main fabric"
                  );

                const showFinishSelection =
                  finishFound &&
                  !!product?.finish_req &&
                  product?.allowed_finishes !== "*N" &&
                  (mappedOptions["upholstery_options"].length >= 1 ||
                    mainFabricOption);
                if (isTabsView) {
                  return (
                    <>
                      {showFinishSelection && (
                        <div
                          id="finish-selector"
                          className={`options-selector-tab ${
                            opened.finish ? "active" : ""
                          }`}
                          onClick={() => {
                            let openedState = { ...opened };
                            openedState = Object.keys(openedState).forEach(
                              (key) => {
                                openedState[key] = false;
                              }
                            );
                            if (finishError) {
                              setFinishError(false);
                            }
                            setOpened({ ...openedState, finish: true });
                            setAllCategoryOptions(null);
                            setOptionSelected(mappedOptions["upholstery_options"]
                                ?.filter(
                                  (opt) => opt.title.toLowerCase() === "finish"
                                )?.[0]);
                          }}
                        >
                          <p className="option-selector-tab-title">
                            Finish Options
                          </p>
                        </div>
                      )}
                      {(moreUpholsteryOptions?.length || key !== "upholstery_options") && <div
                        className={`options-selector-tab ${
                          opened[key] ? "active" : ""
                        }`}
                        onClick={() => {
                          let openedState = { ...opened };
                          openedState = Object.keys(openedState).forEach(
                            (key) => {
                              openedState[key] = false;
                            }
                          );
                          if (finishError) {
                            setFinishError(false);
                          }
                          setOpened({ ...openedState, [key]: true });
                          setOptionSelected(mappedOptions[key][0]);
                          setAllCategoryOptions(mappedOptions[key]);
                        }}
                      >
                        <p className="option-selector-tab-title">
                          {mappedOptions[key][0].category_options}
                        </p>
                      </div>}
                    </>
                  );
                }

                return (
                  <>
                    {showFinishSelection && (
                      <div
                        id="finish-selector"
                        className="options-selector-container"
                      >
                        <div
                          className="option-title-container view-more-title"
                          onClick={() => {
                            let openedState = { ...opened };
                            if (finishError) {
                              setFinishError(false);
                            }
                            if (openedState["finish"]) {
                              optionsAnimation(".select-options-holder");
                              setTimeout(() => {
                                setOpened({ ...openedState, finish: false });
                              }, 500);
                            } else {
                              setOpened({ ...openedState, finish: true });
                            }
                          }}
                        >
                          <h1 className="option-title">Finish Options</h1>
                          <IconContainer opened={opened["finish"]} />
                        </div>
                        {opened["finish"] && (
                          <>
                            <div
                              className={`select-options-holder ${
                                opened["finish"] ? "slide-in" : "slide-out"
                              }`}
                            >
                              {mappedOptions["upholstery_options"]
                                ?.filter(
                                  (opt) => opt.title.toLowerCase() === "finish"
                                )
                                ?.map((opt) => (
                                  <Selector
                                    productImage={productImage}
                                    afterFirstOpenOptionState={
                                      afterFirstOpenOptionState
                                    }
                                    customName={customName}
                                    option={opt}
                                    optionDispatch={optionDispatch}
                                    isCustom={isCustom}
                                    player={player}
                                    optionState={optionState}
                                    isFinish={true}
                                    sku={sku}
                                    leatherAvailable={leatherAvailable}
                                    isPelleProvisions={isPelleProvisions}
                                    finishOption={finishOption}
                                    optionSku={
                                      optionState[
                                        opt?.title
                                          ?.toLowerCase()
                                          .replace(/ /g, "_")
                                      ]
                                    }
                                  />
                                ))}
                            </div>
                            {finishError && (
                              <div className="w-full d-flex justify-content-end">
                                <p className="error-finish-pdp">
                                  Finish is required
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    {((key === "upholstery_options" &&
                      mappedOptions[key].length >= 1) ||
                      key !== "upholstery_options") && (
                      <div
                        key={key}
                        id={key}
                        className="options-selector-container"
                      >
                        {mappedOptions[key][0].category_options !==
                          "Upholstery Options" && (
                          <div
                            className="option-title-container view-more-title"
                            onClick={() => {
                              let openedState = { ...opened };
                              if (openedState[key]) {
                                optionsAnimation(".select-options-holder");
                                setTimeout(() => {
                                  setOpened({ ...openedState, [key]: false });
                                }, 500);
                              } else {
                                setOpened({ ...openedState, [key]: true });
                              }
                            }}
                          >
                            <h1 className="option-title">
                              {mappedOptions[key][0].category_options}
                            </h1>
                            <IconContainer opened={opened[key]} />
                          </div>
                        )}
                        {opened[key] && (
                          <div
                            className={`select-options-holder ${
                              opened[key] ? "slide-in" : "slide-out"
                            }`}
                          >
                            {mappedOptions[key]
                              ?.filter(
                                (opt) =>
                                  ![
                                    "nail band",
                                    "throw pillow decorative cord",
                                  ].includes(opt.title.toLowerCase())
                              )
                              ?.map((opt) => (
                                <>
                                  {opt.category_options.toLowerCase() ===
                                  "upholstery options" ? (
                                    <>
                                      {opt.title.toLowerCase() ===
                                        "main fabric" && (
                                        <Selector
                                          productImage={productImage}
                                          customName={customName}
                                          option={opt}
                                          optionDispatch={optionDispatch}
                                          optionState={optionState}
                                          isCustom={isCustom}
                                          player={player}
                                          sku={sku}
                                          leatherAvailable={leatherAvailable}
                                          isPelleProvisions={isPelleProvisions}
                                          finishOption={finishOption}
                                          optionSku={
                                            optionState[
                                              opt.title
                                                .toLowerCase()
                                                .replace(/ /g, "_")
                                            ]
                                          }
                                        />
                                      )}
                                    </>
                                  ) : opt.category_options.toLowerCase() ===
                                    "additional options" ? (
                                    <Dropdown
                                      selectedOption={
                                        !opt.value[0].price
                                          ? {
                                              label: `${opt.value[0].title} ${
                                                customer ? "(standard)" : ""
                                              }`,
                                              value: opt.value[0].option_id,
                                            }
                                          : false
                                      }
                                      option={opt}
                                      optionDispatch={optionDispatch}
                                      optionSku={
                                        optionState[
                                          opt.title
                                            .toLowerCase()
                                            .replace(/ /g, "_")
                                        ]
                                      }
                                    />
                                  ) : (
                                    <Selector
                                      productImage={productImage}
                                      customName={customName}
                                      key={opt.option_id}
                                      afterFirstOpenOptionState={
                                        afterFirstOpenOptionState
                                      }
                                      option={opt}
                                      player={player}
                                      optionDispatch={optionDispatch}
                                      optionState={optionState}
                                      isCustom={isCustom}
                                      sku={sku}
                                      leatherAvailable={leatherAvailable}
                                      isPelleProvisions={isPelleProvisions}
                                      finishOption={finishOption}
                                      optionSku={
                                        optionState[
                                          opt.title
                                            .toLowerCase()
                                            .replace(/ /g, "_")
                                        ]
                                      }
                                    />
                                  )}
                                </>
                              ))}
                          </div>
                        )}
                        {moreUpholsteryOptions && (
                          <div
                            className="view-more-upholstery-container"
                            id="viewMoreUpholsteryOptions"
                          >
                            <div
                              className="view-more-title"
                              onClick={() => {
                                let openedState = { ...opened };
                                if (
                                  openedState["view_more_upholstery_options"]
                                ) {
                                  optionsAnimation(".select-options-holder");
                                  setTimeout(() => {
                                    setOpened({
                                      ...openedState,
                                      view_more_upholstery_options: false,
                                    });
                                  }, 500);
                                } else {
                                  setOpened({
                                    ...openedState,
                                    view_more_upholstery_options: true,
                                  });
                                }
                              }}
                            >
                              <h1 className="option-title">
                                Upholstery Options
                              </h1>
                              <IconContainer
                                opened={opened["view_more_upholstery_options"]}
                              />
                            </div>
                            {opened["view_more_upholstery_options"] ? (
                              <div
                                className={`select-options-holder ${
                                  opened["view_more_upholstery_options"]
                                    ? "slide-in"
                                    : "slide-out"
                                }`}
                              >
                                {mainFabricOption && (
                                  <Selector
                                    productImage={productImage}
                                    customName={customName}
                                    afterFirstOpenOptionState={
                                      afterFirstOpenOptionState
                                    }
                                    option={mainFabricOption}
                                    key="viewMore"
                                    optionDispatch={optionDispatch}
                                    optionState={optionState}
                                    player={player}
                                    isCustom={isCustom}
                                    sku={sku}
                                    leatherAvailable={leatherAvailable}
                                    isPelleProvisions={isPelleProvisions}
                                    finishOption={finishOption}
                                    isOutdoor={isOutdoor}
                                    optionSku={
                                      checkForOutdoor(
                                        optionState[
                                          mainFabricOption.title
                                            .toLowerCase()
                                            .replace(/ /g, "_")
                                        ]
                                      )
                                        ? ""
                                        : optionState[
                                            mainFabricOption.title
                                              .toLowerCase()
                                              .replace(/ /g, "_")
                                          ]
                                    }
                                  />
                                )}
                                {mainFabricOption && (
                                  <div className="container">
                                    <p
                                      className="link"
                                      onClick={() =>
                                        setMoreUpholstery(!moreUpholstery)
                                      }
                                    >
                                      {`${
                                        moreUpholstery ? "Hide" : "Show"
                                      } Additional Upholstery Applications`}
                                    </p>
                                  </div>
                                )}
                                {moreUpholstery || !mainFabricOption
                                  ? moreUpholsteryOptions
                                      ?.filter(
                                        (opt) =>
                                          ![
                                            "decorative cord",
                                            "decorative tape frame",
                                            "base trim",
                                          ].includes(opt.title.toLowerCase())
                                      )
                                      .map((additionalOption, index) => (
                                        <Selector
                                          productImage={productImage}
                                          customName={customName}
                                          afterFirstOpenOptionState={
                                            afterFirstOpenOptionState
                                          }
                                          option={additionalOption}
                                          key={`viewMore${index}`}
                                          optionDispatch={optionDispatch}
                                          optionState={optionState}
                                          player={player}
                                          sku={sku}
                                          leatherAvailable={leatherAvailable}
                                          isPelleProvisions={isPelleProvisions}
                                          finishOption={finishOption}
                                          isCustom={isCustom}
                                          optionSku={
                                            optionState[
                                              additionalOption.title
                                                .toLowerCase()
                                                .replace(/ /g, "_")
                                            ]
                                          }
                                        />
                                      ))
                                  : null}
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                );
              })}
          {mappedOptions?.["view_more_options"] && !isTabsView && (
            <div
              className="view-more-options-container"
              onClick={() => setCheckboxVisible(true)}
            >
              <div className="option-title-container">
                <h1 className="option-title">
                  {mappedOptions["view_more_options"][0].category_options}
                </h1>
                <Icon icon="arrowRightWhite" />
              </div>
            </div>
          )}
          {options?.length > 4 && (
            <div className="help">
              <p>
                Need assistance or have a question? Chat with <br />
                <Link target="_blank" to="/contact">
                  Customer Service
                </Link>
              </p>
            </div>
          )}
        </div>
      )}
      {isTabsView && (
        <div>
          <ModalOptionSelection
            productImage={productImage}
            afterFirstOpenOptionState={afterFirstOpenOptionState}
            customName={customName}
            option={optionSelected}
            optionDispatch={optionDispatch}
            isCustom={isCustom}
            player={player}
            optionState={optionState}
            isFinish={true}
            sku={sku}
            leatherAvailable={leatherAvailable}
            isPelleProvisions={isPelleProvisions}
            finishOption={finishOption}
            optionSku={
              optionState[
                optionSelected?.title?.toLowerCase().replace(/ /g, "_")
              ]
            }
            allCategoryOptions={allCategoryOptions}
            setOptionSelected={setOptionSelected}
            mappedOptions={mappedOptions}
            opened={opened}
            sortedAndFilteredMappedOptions={sortedAndFilteredMappedOptions}
            setOpened={setOpened}
            setAllCategoryOptions={setAllCategoryOptions}
          />
        </div>
      )}
    </>
  );
};

export default Options;
