import React, { useMemo, useState } from "react";
import Price from "core/components/price";
import useCustomer from "app/state/hooks/customer/useCustomer";
import { inStockLogicMethod } from "app/pages/product/components/toolbar/helper.js";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";
import Summary from "../../../toolbar/components/summary";
import { MDBBtn, MDBTooltip } from "mdbreact";

const ProductDetails = ({
  product,
  price,
  options,
  optionState,
  dimensionsString,
  setModalOpen,
  customizationOptionsQueryParameters,
}) => {
  const customer = useCustomer().isLoggedIn();
  const inStockLogic = useMemo(() => inStockLogicMethod(product, customer), [
    customer,
    product,
  ]);
  const [buildSummary, setBuildSummary] = useState(false);
  const mappedOptions = options?.reduce(
    (accumulator, option) =>
      option?.category_options
        ? {
            ...accumulator,
            [option?.category_options.toLowerCase().split(" ").join("_")]: (
              accumulator[
                option?.category_options?.toLowerCase().split(" ").join("_")
              ] || []
            ).concat(option),
          }
        : accumulator,
    {}
  );

  return (
    <>
      <div className="configureProductModal-details">
        <h1 className={"product-name"}>{product?.name}</h1>
        {customer && (
          <div className="d-flex justify-content-between items-center">
            {!!price && (
              <h2 className={"product-price"}>
                <Price price={price} type={"fixed"} />
              </h2>
            )}
            <p className="generic-instock">{inStockLogic}</p>
          </div>
        )}
        <div className="relative">
          <MDBTooltip className="tooltip-info" placement="left">
            <MDBBtn className="tooltip-btn configure-modal-info-btn">
              <Icon icon="Info" className="configure-modal-info-icon" />
            </MDBBtn>
            <span id={"tooltip-info"} className="tooltip-info-txt">
              Image shown does not correspond to selected options
            </span>
          </MDBTooltip>

          <img
            src={product?.image?.url}
            alt={product?.image?.label || "The product"}
          />
        </div>
        <Button
          className={
            "btn-primary w-full confirm-configuration-btn generic-button-font"
          }
          onClick={() => setModalOpen(false)}
        >
          <Icon icon={"Checks"} /> Confirm Configuration
        </Button>
        <Button
          className={
            "btn-secondary w-full view-build-summary-btn generic-button-font"
          }
          onClick={() => setBuildSummary(true)}
        >
          <Icon icon={"listDashes"} /> View Build Summary
        </Button>
      </div>
      {buildSummary ? (
        <Summary
          options={mappedOptions}
          setBuildSummary={setBuildSummary}
          buildSummary={buildSummary}
          optionState={optionState}
          size={{
            width: product?.width,
            height: product?.height,
            depth: product?.depth,
          }}
          isTabView
          product={product}
          dimensionsString={dimensionsString}
          customizationOptionsQueryParameters={
            customizationOptionsQueryParameters
          }
          price={price}
        />
      ) : null}
    </>
  );
};

export default ProductDetails;
