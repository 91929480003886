import React from "react";
import CmsBlock from "app/layout/cmsBlock";

const Timeline = () => {
    return ( <>
    <CmsBlock id="timeline1" />
    <CmsBlock id="timeline2" />
    <CmsBlock id="timeline3" />
    <CmsBlock id="timeline4" />
    <CmsBlock id="timeline5" />
    <CmsBlock id="timeline6" />
    <CmsBlock id="timeline7" />
    </> );
}
 
export default Timeline;