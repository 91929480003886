import React, { useCallback, useEffect, useMemo, useState } from "react";
import Sidebar from "../selector/components/sidebar";
import { useGetFabricOrFinishFromSku } from "app/pages/product/utils";
import { generateNailSizeOption } from "../selector/selector";
import Dropdown from "../dropdown";
import useCustomer from "app/state/hooks/customer/useCustomer";

const ModalOptionSelection = ({
  option,
  productImage,
  optionDispatch,
  optionState,
  isCustom,
  player,
  isFinish,
  afterFirstOpenOptionState,
  sku,
  leatherAvailable,
  isPelleProvisions,
  finishOption,
  customName,
  isOutdoor,
  optionSku,
  allCategoryOptions,
  setOptionSelected,
  mappedOptions,
  opened,
  sortedAndFilteredMappedOptions,
  setOpened,
  setAllCategoryOptions,
}) => {
  const customer = useCustomer().isLoggedIn();
  const selOption = useGetFabricOrFinishFromSku(optionSku);

  const nailsSelection = useMemo(
    () => option?.category_options === "Nailhead Trim Options",
    [option]
  );
  const [showAllOptions, setShowAllOptions] = useState(nailsSelection);
  useEffect(() => {
    setShowAllOptions(nailsSelection);
  }, [nailsSelection]);

  const selectedOption = useMemo(
    () =>
      !selOption && typeof optionSku === "number"
        ? generateNailSizeOption(
            option?.value?.find((val) => val.option_type_id === optionSku)
          )
        : selOption,
    [selOption, optionSku, option]
  );

  const saveSelectionFn = useCallback(() => {
    const currentMainOption =
      option?.title === "Finish"
        ? -1
        : sortedAndFilteredMappedOptions.findIndex(
            (optionM) => opened[optionM]
          );
    if (!showAllOptions || nailsSelection) {
      let openedState = { ...opened };
      openedState = Object.keys(openedState).forEach((key) => {
        openedState[key] = false;
      });

      console.log(sortedAndFilteredMappedOptions, currentMainOption);
      if (sortedAndFilteredMappedOptions[currentMainOption + 1]) {
        setOptionSelected(
          mappedOptions[
            sortedAndFilteredMappedOptions[currentMainOption + 1]
          ][0]
        );
        setOpened({
          ...openedState,
          [sortedAndFilteredMappedOptions[currentMainOption + 1]]: true,
        });
        setAllCategoryOptions(
          mappedOptions[sortedAndFilteredMappedOptions[currentMainOption + 1]]
        );
      }
    }
  }, [showAllOptions, option, opened, mappedOptions, nailsSelection]);
  console.log("opened", opened);

  console.log("selector option", option);
  if (option?.category_options === "Additional Options") {
    return (
      <div className="option-dropdown-selection">
        {allCategoryOptions?.map((opt) => (
          <Dropdown
            key={opt.id}
            selectedOption={
              !opt.value[0].price
                ? {
                    label: `${opt.value[0].title} ${
                      customer ? "(standard)" : ""
                    }`,
                    value: opt.value[0].option_id,
                  }
                : false
            }
            option={opt}
            optionDispatch={optionDispatch}
            optionSku={optionState[opt.title.toLowerCase().replace(/ /g, "_")]}
          />
        ))}
      </div>
    );
  }
  return (
    <div className="modal-option-selection">
      {allCategoryOptions?.length > 1 && !nailsSelection && (
        <div
          role="button"
          className="show-additional-trigger"
          onClick={() => setShowAllOptions(!showAllOptions)}
          onKeyPress={() => setShowAllOptions(!showAllOptions)}
          tabIndex={-1}
        >
          {showAllOptions ? "Hide" : "Show"} Additional{" "}
          {option?.category_options} Options
        </div>
      )}
      {showAllOptions && (
        <div className="tabs-container">
          {allCategoryOptions
            ?.filter(
              (opt) =>
                ![
                  "finish",
                  "nail band",
                  "throw pillow decorative cord",
                ].includes(opt.title.toLowerCase())
            )
            ?.map((opt) => (
              <div
                onClick={() => setOptionSelected(opt)}
                key={opt?.title}
                className={`tabs-option ${
                  opt?.title === option?.title ? "active-tabs-option" : ""
                }`}
                onKeyDown={() => setOptionSelected(opt)}
                role="button"
                tabIndex={-1}
              >
                {opt?.title}
              </div>
            ))}
        </div>
      )}
      <Sidebar
        visible={true}
        setVisible={() => {}}
        option={option}
        selectedOption={selectedOption}
        productImage={productImage}
        optionDispatch={optionDispatch}
        key={option?.id}
        isCustom={isCustom}
        player={player}
        optionState={optionState}
        isFinish={isFinish}
        afterFirstOpenOptionState={afterFirstOpenOptionState}
        sku={sku}
        leatherAvailable={leatherAvailable}
        isPelleProvisions={isPelleProvisions}
        finishOption={finishOption}
        customName={customName}
        isOutdoor={isOutdoor}
        isTabView={true}
        saveSelectionFn={saveSelectionFn}
      />
    </div>
  );
};

export default ModalOptionSelection;
