import gql from "graphql-tag";

const GET_RECENTLY_VIEWED = gql`
  query GetRecentlyViewedProducts {
    getRecentlyViewedProducts {
      id
      sku
      name
      url_key
      ffi_lanect
      ffi_laners
      ffi_lanesl
      ffi_isvisible
      is_new
      description {
        html
      }
      image {
        url(width: 700, height: 700)
        label
      }
      small_image {
        url(width: 265, height: 265)
        label
      }
      ffi_frmdimw
      ffi_frmdimh
      price_range {
        minimum_price {
          final_price {
            value
            currency
          }
        }
      }
      thumbnail {
        url(width: 150, height: 150)
        label
      }
    }
  }
`;

export { GET_RECENTLY_VIEWED };
